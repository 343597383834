.container-repairer {
    padding: 3rem 0rem;
}

.msg_part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.succes_msg {
    display: block;
    font-weight: 500;
    font-size: 18px;
    color: #639A46;
}

.error_msg {
    display: block;
    font-weight: 500;
    font-size: 18px;
    color: rgb(218, 36, 36) !important;
}

.register-repairer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
}

.register-repairer .privateForm input {
    height: 45px;
}

.privateForm {
    margin-bottom: 2.4rem !important;
}

.left-register {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}

.register-repairer .privateForm span {
    font-weight: 400;
    font-size: 15px;
    color: #111111;
}

.registerTextarea {
    grid-column: 1 / -1;
    margin-bottom: 0 !important;
}

.registerTextarea textarea {
    width: 100%;
    height: 64px;
    border-radius: 5px;
    border: 1px solid #B7B7B7;
    padding: 1rem;
    margin-top: 0.6rem;
    margin-bottom: 0;
}

.profilImg {
    margin-top: 0.8rem;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* gap: 2rem; */
    margin-bottom: 3rem;
}

.profilImg label {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F3F3F3;
    border-radius: 5px;
    cursor: pointer !important;
    width: 210px;
    height: 210px;
    max-width: 100%;
}

.ml_left label {
    width: 210px;
    height: 210px;
}

.ml_right label {
    width: 100%;
    height: 90px;
}

.register-repairer input[type="file"] {
    display: none;
}

.profilImg img {
    width: auto;
    height: 16.5rem;
    border: 1px solid #F3F3F3;
}

.right-images button {
    margin-left: auto;
    margin-top: 2rem;
}

.repairers__work {
    display: block;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 0.5rem; */
}

.row_rep {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 0.5rem;
    width: 100%;
    position: relative;
}

.hidden_rep {
    display: none;
}

.visible_rep {
    display: grid;
}

.hide_item_rep {
    display: block;
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/images/remove_icon.svg");
    /* background: url("../img/plus.svg") no-repeat center / cover; */
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: -30px;
}

.add__select {
    padding: 1rem 1.5rem !important;
    font-size: 2.2rem !important;
}

.register-repairer p {
    color: rgb(218, 36, 36);
}

.repairerSelect {
    margin-bottom: 2rem;
    position: relative;

}

.repairerSelect .css-nm6zrf-control {
    margin-top: 5px;
    min-height: 5rem !important;
}

.repairerSelect span {
    font-weight: 400;
    font-size: 12px;
    color: #8E8E8E;
    margin-bottom: 0.6rem;
    display: block;
}

.repairTodoSect {
    grid-column: span 2;
    width: 100%;
}

.repairAddTodoHead {
    display: flex;
    align-items: center;
    margin: 10px 0 0px 0;
}

.rpTodoTitle {
    font-weight: bold;
    font-size: 18px;
    color: #434242;
}

.repairAddTodoItem {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.repairAddTodoItem span {
    font-weight: 500;
    font-size: 15px;
    color: #639A46;
    margin-left: 0px;
    cursor: pointer;
}

.btn.add_todo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px !important;
    height: 20px !important;
    padding: 0px !important;
    font-size: 20px !important;
    color: #639A46;
    background-color: transparent;
}

.rpRegisterBtn {
    grid-column: span 2;
}

.rpRegisterBtn button {
    min-width: 300px;
    margin-left: auto;
}

.right_img_sect {
    display: flex;
    flex-direction: column;
}

.commnet_sect {
    margin-top: 46px;
}

.multi_images {
    display: block;
    width: 100%;
}

.m_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}

.m_col {
    display: block;
    float: left;
    width: 50%;
    padding: 0 12px;
    padding-bottom: 32px;
}

.ml_item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.ml_left {
    display: block;
    float: left;
    width: 210px;
}

.ml_right {
    display: block;
    float: left;
    width: calc(100% - 210px);
    padding-left: 50px;
}


@media only screen and (max-width: 1024px) {
    .container-repairer {
        padding: 1.5rem 0rem;
    }

    /* .register-repairer {
        grid-template-columns: 1fr;
        gap: 0rem;
    } */
}

@media only screen and (max-width: 800px) {
    .right-images button {
        width: 100%;
    }

    .profilImg label {
        height: 13rem;
    }

    .profilImg img {
        height: 13rem;
    }

    .register-repairer .privateForm input {
        height: 4rem;
    }

    .register-repairer {
        display: block;
    }

    .right_img_sect {
        padding: 20px 0;
    }

    .right-images {
        display: flex;
        flex-direction: column-reverse;
    }

    .image_sect_upl {
        width: 100%;
        margin-top: 20px;
    }

    .commnet_sect {
        width: 100%;
        margin-top: 0px;
    }

    .profilImg label {
        width: 100%;
        height: 210px;
    }

    .left-register {
        display: flex;
        flex-wrap: wrap;
    }

    .left-register .privateForm {
        width: 100%;
    }

    .left-register .repairerSelect {
        width: 100%;
    }

    .profilImg {
        margin-bottom: 0.5rem;
    }

    .rpRegisterBtn button {
        min-width: 100%;
        margin-left: auto;
    }
}

@media only screen and (max-width: 600px) {
    .right-images button {
        width: 100%;
    }

    .profilImg label {
        height: 13rem;
    }

    .profilImg img {
        height: 13rem;
    }

    .register-repairer .privateForm input {
        height: 4rem;
    }

    .register-repairer {
        display: block;
    }

    .right_img_sect {
        padding: 20px 0;
    }

    .right-images {
        display: flex;
        flex-direction: column-reverse;
    }

    .image_sect_upl {
        width: 100%;
        margin-top: 20px;
    }

    .commnet_sect {
        width: 100%;
        margin-top: 0px;
    }

    .profilImg label {
        width: 100%;
        height: 210px;
    }

    .left-register {
        display: flex;
        flex-wrap: wrap;
    }

    .left-register .privateForm {
        width: 100%;
    }

    .left-register .repairerSelect {
        width: 100%;
    }

    .profilImg {
        margin-bottom: 0.5rem;
    }

    .rpRegisterBtn button {
        min-width: 100%;
        margin-left: auto;
    }
}
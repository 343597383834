/* ----btn---- */
.btn {
    padding: 1rem 2.5rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    border-radius: 5px;
    cursor: pointer;
}

.oemCode button {
    border-radius: 3px !important;
}

.btn__primary {
    background: var(--color-primary);
}

.btn__primary:hover {
    opacity: 0.9;
}

.btn__gray {
    background: #E3E3E3;
    color: #7C8489;
}

.btn__nav {
    padding: 1rem 1rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    border-radius: 3px;
}

.btn__order {
    min-width: 8rem;
    padding: 1rem 1rem;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    border-radius: 5px;
}

.btn__card {
    padding: 0.7rem 1rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1rem;
    transition: var(--transition);
    display: inline-block;
    color: var(--color-white);
    border-radius: 3px;
    cursor: pointer;
}

.btn__card {
    padding: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    left: auto;
    cursor: pointer;
}

.btn__card__black {
    background: var(--color-black);
}

.btn__card__primary {
    background: var(--color-primary);
}

.btn__card__black:hover {
    opacity: 0.9;
}

.btn__card__primary:hover {
    opacity: 0.9;
}

.btn__pink {
    background: #FFE2E2;
}

.btn__light {
    border: 1px solid #858585;
    border-radius: 5px;
    background: #fff;
    color: #858585;
    font-weight: 400;
    font-size: 14px;
    padding: 1rem 3rem;
}

.btn__light:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-black);
}

.activeLightBtn {
    border: 1px solid var(--color-primary);
    color: var(--color-black);
}

.LightBtn {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: transparent;
}

.btn__transparent {
    color: var(--color-gray);
    background: transparent;
}

.btn__pagination {
    padding: 6px 10px;
    border-radius: 2px;
    background: var(--bg-gray2);
    color: var(--color-black);
    margin: 0.5rem;
    cursor: pointer;
}

.activePage {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.pagination {
    display: flex;
    padding: 2rem;
    justify-content: center;
    flex-wrap: wrap;
}

.pagination button {
    font-size: 1.3rem;
}

.disabledPg {
    opacity: 0.5;
}

.pagination button:nth-child(2) {
    display: none;
}

.pagination .btn__pagination:nth-last-child(2) {
    display: none !important;
}

@media only screen and (max-width: 1024px) {
    .btn__nav {
        display: none;
    }
}
footer {
    margin-top: 10rem;
    background: #F1F1F1;
}

.foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 3.2rem 0rem;
}

.socialIcons {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.socialIcons p a span {
    margin-right: 24px;
}

.socialIcons p a {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    color: #3D3D3D;
}

.footIcon p {
    margin-right: 24px;
}

.footIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.8rem;
}

.footIcon p {
    font-weight: 400;
    font-size: 16px;
    color: #3D3D3D;
}

.footIcon ul {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.footIcon ul a {
    color: #3D3D3D;
    font-size: 2.1rem;
}

.footIcon ul a:nth-last-child(1) {
    font-size: 1.9rem;
}

.footIcon ul a:nth-last-child(2) {
    font-size: 2.2rem;
}

.copyright {
    background-color: var(--color-white);
}

.copyright__container {
    height: 4.8rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
}

.copyright__container ul {
    display: flex;
    gap: 4.6rem;
    align-items: center;
}

.copyright__container ul li a {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #434242;
}

.copyright__container p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #757575;
}

.mobil__foot__icon {
    display: none;
}

.countUp {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 15rem;
}

.countUp span {
    padding: 3px;
    letter-spacing: 1px;
    height: 27px;
    border-radius: 2px;
    color: #fff;
}

@media only screen and (max-width: 1024px) {
    footer {
        margin-top: 5rem;
    }

    .copyright__container ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .footIcon_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .footlogo {
        width: 120px;
    }

    .mobil__foot__icon {
        display: block;
        /* float: left; */
    }

    .mobil__foot__icon p {
        display: none;
    }

    .socialIcons .footIcon {
        display: none;
    }

    .socialIcons {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
    }

    .socialIcons p {
        width: 50%;
        float: left;
    }

    .socialIcons p a {
        flex-direction: column;
        font-size: 13px;
    }

    .socialIcons p:first-child a {
        align-items: flex-end;
        flex-direction: column;
        font-size: 13px;
    }

    .socialIcons p a span {
        display: block;
        font-weight: bold;
        padding-bottom: 0.6rem;
        margin: 0;
    }

    .mobil__foot__icon p {
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
    }

    .copyright__container {
        height: auto;
        padding: 1.5rem 0rem;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 1rem;
    }

    .copyright__container ul li a {
        color: #434242;
    }

    .copyright {
        max-width: 100% !important;
        background: #ECECEC;
        padding: 0.8rem 1.4rem 1.6rem 1.4rem;
    }

    .copyright__container {
        max-width: 100% !important;
        padding: 0 !important;
    }

    .copyright__container p {
        font-size: 13px;
        margin-top: 1rem;
    }

    .foot {
        background-color: #DEDEDE;
        align-items: flex-start;
        flex-wrap: wrap;
        max-width: 100% !important;
        padding: 1.6rem 1.4rem;
    }

    .countUp {
        display: none;
        justify-content: flex-end;
        padding-top: 10px;
        min-width: 0;
    }

}


@media only screen and (max-width: 600px) {
    footer {
        margin-top: 5rem;
    }

    .copyright__container ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }

    .footIcon_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .footlogo {
        width: 120px;
    }

    .mobil__foot__icon {
        display: block;
        /* float: left; */
    }

    .mobil__foot__icon p {
        display: none;
    }

    .socialIcons .footIcon {
        display: none;
    }

    .socialIcons {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
    }

    .socialIcons p {
        width: 50%;
        float: left;
    }

    .socialIcons p a {
        flex-direction: column;
        font-size: 13px;
    }

    .socialIcons p:first-child a {
        align-items: flex-end;
        flex-direction: column;
        font-size: 13px;
    }

    .socialIcons p a span {
        display: block;
        font-weight: bold;
        padding-bottom: 0.6rem;
        margin: 0;
    }

    .mobil__foot__icon p {
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
    }

    .copyright {
        padding: 0.8rem 1.4rem 1.6rem 1.4rem;
    }

    .copyright__container {
        max-width: 100% !important;
        padding: 0 !important;
    }

    .copyright__container p {
        font-size: 13px;
        margin-top: 1rem;
    }

    .foot {
        background-color: #DEDEDE;
        align-items: flex-start;
        flex-wrap: wrap;
        max-width: 100% !important;
        padding: 1.6rem 1.4rem;
    }

    .countUp {
        display: none;
        justify-content: flex-end;
        padding-top: 10px;
        min-width: 0;
    }
}
/* Lightbox image ölçüsünü təyin edin */

/* Əlavə: Overlay fon rəngi üçün */
.ril-outer {
  background-color: rgba(5, 5, 5, 0.849) !important;
  position: relative;
}

.ril-outer img {
  width: auto;
}

/* slider */
.more_sect {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 1.6rem;
}

.moreItems {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.2rem;
  padding: 1rem 3rem;
  margin: 1.2rem auto 2rem auto;
  color: #111;
  text-align: center;
  font-family: Roboto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.slider__title {
  display: flex;
  padding: 2.2rem 0rem 1rem;
  justify-content: space-between;
  align-items: center;
}

.detailDefaultImg {
  /* background-color: #ff0000 !important; */
  position: absolute;
  left: 0;
  top: 0;
}

.autodetail_sect .detailDefaultImg {
  position: relative;
}

.detailDefaultImgMobile {
  position: relative;
  max-width: 100%;
}

.detailSlider {
  position: relative;
  min-height: 230px;
}

.slider__title h4 {
  color: var(--color-black);
  font-size: 1.6rem;
}

.slider__title a {
  font-weight: 500;
  font-size: 14px;
  color: #0972ac;
  margin-right: 1rem;
}

.slick-track {
  padding: 5px 0px;
}

.slick-prev,
.slick-next {
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #333;
  color: #333;
  /* Arrow color */
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
/* 
.slick-prev:before,
.slick-next:before {
  color: #11111163 !important;
} */

/* .slick-slider .slick-prev {
  display: none !important;
}

.slick-slider .slick-next {
  display: none !important;
} */

.slick-slider .slider__card {
  width: 20rem;
  border: none !important;
}

/* detail slider */
.detailSlider {
  width: 32rem;
}

.detailSlider .smallImages {
  display: flex !important;
}

.detailSlider .smallImages .slick-slide {
  width: 116px !important;
  padding: 0 4px;
}

.detailSlider .smallImages .slick-current img {
  border: 1px solid #aeaeae !important;
}

.detailSlider .slick-list {
  width: 100%;
}

.oneImg {
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  width: 30rem;
  height: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  z-index: 999;
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
}

.oneImg img {
  height: 206px;
  object-fit: contain;
}

.smallImages {
  width: 100% !important;
  display: flex !important;
  gap: 1rem !important;
}

.smallImg img {
  width: 100% !important;
  height: 80px !important;
  cursor: pointer;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.slick-slide article {
  height: auto;
  background: #fff !important;
}

.avatar-skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.sklet__slider {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 2rem;
}

/* Your existing styles */

/* .zoom-img-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
} */

.normal-img {
  max-height: 54rem;
  width: 100%;
  transition: transform 0.3s ease;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.zoom-img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  transform: scale(4);
  -webkit-transform: scale(4);
  -moz-transform: scale(4);
  -ms-transform: scale(4);
  -o-transform: scale(4);
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
}

.zoom-img {
  width: 100%;
  /* height: auto; */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(3);
  -webkit-transform: scale(3);
  -moz-transform: scale(3);
  -ms-transform: scale(3);
  -o-transform: scale(3);
  transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -ms-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
}

.oneImg:hover .normal-img {
  opacity: 0;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.oneImg:hover .zoom-img {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .slick-slider .slider__card {
    width: 94%;
    max-width: 100%;
  }

  .sklet__slider {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    gap: 1rem;
  }

  .more_sect {
    padding: 2.4rem 1.6rem 1rem 1.6rem;
  }

  .moreItems {
    margin: 0 auto;
    padding: 1rem 3rem;
  }
}

@media only screen and (max-width: 400px) {
  .slick-slider .slider__card {
    width: 94%;
    max-width: calc(100% - 16px);
  }
}

@media only screen and (max-width: 360px) {
  .slick-slider .slider__card {
    width: 15rem;
    max-width: calc(100% - 8px);
  }
}

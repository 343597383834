.description_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 0;
}

.desc_info {
    display: block;
    width: 100%;
    padding: 1.6rem;
    border: 1px solid #D7D7D7;
}

.desc_info p {
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
}

.expanded p {
    -webkit-line-clamp: inherit;
}

.auto_logo_crop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    width: auto;
    height: auto;
    max-width: 8rem;
    max-height: 8rem;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.auto_logo_crop img {
    margin: auto !important;
    height: 100% !important;
    height: 100% !important;
    object-fit: contain;
}

.show_about {
    display: block;
    float: right;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    overflow: hidden;
}

.back_content {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../icons/arw_back.svg");
}

.full_content {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-right: 32px;
    cursor: pointer;
}

.full_content::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../icons/arw_right.svg");
}

.desc_row {
    display: flex;
}

.desc_row .item__left {
    width: 30%;
}

.desc_flex {
    display: flex;
    margin-left: auto;
    width: 70%;
}

.desc_flex .desc_info {
    border: none !important;
}

.desc_flex .desc_info p {
    text-align: justify !important;
}

.shop__container {
    margin-top: 2rem !important;
    text-align: center;
    margin-bottom: 6rem !important;
}

.autosalon_container {
    margin-bottom: 0rem !important;
}

.shop__container h3 {
    font-weight: 400;
    font-size: 22px;
    color: var(--color-black);
}

.shop__info {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 5rem 1fr;
    gap: 3rem;
    align-items: flex-start;
}

.hide_items p {
    display: none !important;
}

.hide_items p:first-child {
    display: block !important;
}

.show_adrs .hide_items p {
    display: block !important;
}


.item {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: flex-start;
    padding-top: 1rem;
}

.subscribe {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #181818;
    font-size: 14px;
}

.icons {
    /* margin-top: 2.4rem; */
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    /* gap: 2rem; */
}

.icons a {
    font-size: 2.4rem;
    color: #B9B9B9;
}

.icons a:nth-child(1) {
    font-size: 2.7rem;
}

.lineBorder {
    width: 1.3px;
    height: 90%;
    background: #181818;
    margin: 0px auto;
}

.item p {
    margin-bottom: 1.8rem;

}

.item p a,
.item p div {
    font-weight: 400;
    font-size: 15px;
    color: var(--color-black);
    line-height: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    /* gap: 0.7rem; */
}

.item p a svg,
.item p div svg {
    color: #434242;
    font-size: 1.7rem;
}

.item2 {
    text-align: left;
}

.service {
    font-weight: 500;
    font-size: 18px;
    color: #68A834;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.item__right {
    text-align: left;
}

.item__right p {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.more_text {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 1rem 0;
}

.more_text span:hover {
    background: #f4f4f4;
}

.more_text span {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0.8rem 1.6rem;
    color: #000;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
}


.more_text_cnt {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2.4rem 0 0rem 0;
}

.more_text_cnt span {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-right: 22px;
    color: #fff;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
    border: none !important;
}

.more_text_cnt.clicked span::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.more_text_cnt span::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../icons/arw_drop_w.svg");
}


.autosalon_container h3 {
    display: inline-flex;
    align-items: center;
    width: auto;
    position: relative;
    color: var(--color-white);
    padding-right: 4rem;
}

.autosalon_container h3::after {
    content: "";
    display: block;
    position: relative;
    margin-left: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../icons/tick_salon.svg");
}

.autosalon_container .item p a,
.autosalon_container .item p div {
    color: var(--color-white);
}

.autosalon_container .item p a svg,
.autosalon_container .item p div svg,
.autosalon_container .item p a svg path,
.autosalon_container .item p div svg path {
    fill: #757575;
    color: #757575;
}

.autosalon_container .subscribe {
    color: #fff;
}

.autosalon_container .more_text span:hover {
    background: #444444;
}

.autosalon_container .more_text span {
    color: #fff;
    border: 1px solid #D7D7D7;
}


.auto_logo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
}

.auto_logo img {
    margin-top: 2.4rem;
    max-width: 8rem;
    max-height: 8rem;
    width: auto;
    height: auto;
}

@media only screen and (max-width: 1024px) {
    .shop__container {
        display: none;
    }
}
.order-list-tabs {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 4rem;
}


.order-list-item {
    display: grid;
    grid-template-columns: 1fr 9fr;
    place-items: top;
    gap: 2rem;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 1.9rem 5rem 1.9rem 0.5rem;
    position: relative;
    margin-bottom: 2rem;
}

.order-list-item-img {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.order-list-item-img img {
    width: 59px;
    height: 59px;
    border: 1px solid #D0D0D0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-list-item .downIcon {
    background: transparent;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    right: 2rem;
    top: 5rem;
    color: #858585;

}

.order-item-info {
    font-weight: 400;
    font-size: 1.3rem;
    color: var(--color-black);
}

.order-list-item-info {
    width: 100%;
    height: 100%;
}

.order-list-item-info table {
    width: 100%;
}

.order-list-item-info table thead th {
    padding-bottom: 1.6rem;
    text-align: left;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    color: #757575;
}

.order-list-item-info table tbody td {
    min-width: 80px;
    padding: 4px 8px 4px 0;
    text-align: left;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: var(--color-black);
}

.pay_value {
    color: #D4252A !important;
}

.steps {
    width: 100%;
    margin: 2.4rem 0rem 1.6rem 0rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    display: none;
}

.activeSteps {
    display: flex;
}

.step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.step {
    border: none;
    background-color: #D8D8D8;
    color: #C0C0C0;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
    margin-bottom: 1.3rem;
}

.step.activeStep {
    background-color: var(--color-primary);
    color: #fff;
}

.step-item p {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
    margin-bottom: 0.9rem;
}

.step-item span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #858585;
}

.step-line {
    position: absolute;
    top: 1.8rem;
    left: 4rem;
    width: calc(100% - 8rem);
    height: 2px;
    background: #D0D0D0;
    z-index: -1;
}

.step-item:nth-last-child(2) .step-line {
    right: 0;
}

.step-item:nth-last-child(1) .step-line {
    display: none;
}

.info-mobil {
    display: none;
}

.success {
    color: var(--color-primary);
}

.danger {
    color: #FB3939;
}

.return {
    color: #FFB930;
}

.mobile_tab {
    display: none !important;
    width: auto !important;
}

@media only screen and (max-width: 820px) {
    .order-list-item-info table {
        display: none;
    }

    .info-mobil {
        display: grid;
        gap: 1rem;
        padding-right: 3.2rem;
    }

    .steps {
        justify-content: space-between;
        gap: 1rem;
        /* flex-wrap: wrap; */
        width: calc(100% + 7rem);
        margin: 4rem 0rem 1.6rem -7rem
    }

    .step svg {
        width: 0.8rem;
        height: 0.8rem;
    }

    .step {
        width: 2.4rem;
        height: 2.4rem;
    }

    .step-item p {
        font-size: 13px;
    }

    .order-list-tabs {
        display: none;
        position: relative;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 1.2rem;
    }

    .order-list-tabs.active {
        display: flex;
    }

    .order-list-item {
        gap: 0.8rem;
        border: none;
        border-bottom: 1px solid #D1D1D1;
        padding: 1.9rem 0 1.9rem 0rem;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .step-line {
        top: 1.2rem;
    }

    .order-list-item .downIcon {
        font-size: 1.6rem;
        right: 0;
        top: 2rem;
    }

    .mobile_tab {
        display: block !important;
    }
}


@media only screen and (max-width:768px) {

    .order-list-tabs button {
        width: 100%;
    }
}
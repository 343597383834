.oilsBanner img{
    height: 20rem;
}
.oilTitle{
    padding:2rem 0rem;
}
.motorOils{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap:2rem;
}

.motorOils a{
    background: #F8F8F8;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    padding:1.5rem;
}
.motorOils a img{
   height: 20rem;
   object-fit: contain;
}

@media only screen and (max-width: 600px) {
    .motorOils a img{
        height: 15rem;
     }
}
.btnFlex_end{
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
}

.send__btn{
  height: 45px;
  margin-top: 2rem;
  width: 40%;
}


.form__modal h5{
  grid-column: 1/-1;
  font-size: 15px;
  font-weight: 500;
  color: #181818;
}

.textarea_item{
  display: grid;
  grid-column: 1/-1;

}

.textarea_item textarea{
  border: 1px solid #B7B7B7;
  border-radius: 5px;
  padding: 10px;
  margin-top: 0.6rem;

}

.person__form{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1/-1;
  gap: 1rem;
}

@media only screen and (max-width: 600px) {
  .person__form {
      padding: 0rem 0rem;
      grid-template-columns: 1fr;
  }
  .form__item {
      margin-top: 0.5rem;
  }

 
}

.box{
    /* width: 15.6rem; */
    text-align: center;
    padding: 0.8rem 1rem 1.5rem;
    position: relative;
    border:1px solid transparent;
    transition: var(--transition);
    /* border: 1px solid #DDDDDD; */
    border-radius: 3px;
}

.box:hover{
    border: 1px solid #DDDDDD;
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
}

.box .box__count{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #A6A6A6;
}
.box .box__count span{
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #A6A6A6;
    display: inline-block;
}

.box img{
    width: 11.5rem;
    object-fit: cover;
    object-position: center;
}

.box .box__link{
    margin-top: 1.8rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    color: var(--color-black);
    display: flex;
    gap: 1rem !important ;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* .box .rightIcon{
    position: absolute;
    right:1.5rem;
} */
.box__count{
    margin-bottom: 0.8rem;
}

@media only screen and (max-width: 820px) {
    
    .box{
        width: auto;
    }
    .box .box__link{
        margin-top: 1.5rem;
        font-size: 1.4rem;
    }
    .rightIcon{
        display: none !important;
    }
  }
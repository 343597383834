.card {
    position: relative;
    /* border: 1px solid #D0D0D0; */
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.card .card__image {
    background: #fff;
    /* background: #D1D1D1; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
    /* padding: 1rem; */
}

.card .card__image img {
    border-radius: 5px 5px 0px 0px;
    height: 12.7rem;
    object-position: center;
}

.card__body {
    border-radius: 0px 0px 5px 5px;
    padding: 1.3rem 1rem 1.3rem;
    background-color: var(--color-white);
}

.d_flex {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    flex-wrap: wrap;
    position: relative;
}

/* .card span {
    position: absolute;
    top: 1.4rem;
    right: 1.3rem;
    cursor: pointer;
} */
.card span {
    position: relative;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.card span svg {
    width: 100%;
    height: 100%;
}


.card:hover {
    transition: var(--transition);
    background: #FFFFFF;
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
    /* border: 1px solid transparent; */
}

.discount {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-decoration: line-through;
    color: #FF0000;
}

.d_flex {
    height: 24px;
}

.d_flex p {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

/* .card__text{
    height: 3rem;
    overflow: hidden;
} */
.card__text {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1;
    color: #000;
    width: 100%;
    /* width: calc(100% - 32px); */
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
}

.card_prices {
    font-weight: 500 !important;
    font-size: 1.8rem !important;
    line-height: 1.5 !important;
    color: #D4252A !important;
    /* margin-left: auto !important; */
    margin-right: auto !important;
}

.card_prices strong {
    display: flex;
    align-items: center;
}

.manat {
    display: block;
    width: 1.6rem !important;
    height: auto !important;
    margin-left: 8px;
}

.manat svg {
    display: block;
    width: 100%;
}

.card__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

}

.card__bottom p {
    color: #979797;
    font-size: 1.2rem;
    margin-right: 3px;
}

.card_view {
    margin-left: auto;
}

@media only screen and (max-width: 600px) {
    .d_flex strong {
        font-size: 1.4rem;
    }

    .card__text {
        font-size: 1.1rem;
    }

    .manat {
        width: 1.4rem !important;
        margin-left: 4px;
    }
}
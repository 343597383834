.page_title {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1.6rem 0;
  border-bottom: 1px solid #D7D7D7;
}

.page_title .page__link {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.page_title .page__link a {
  color: var(--color-black);
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  color: #181818;
}

.page_title .page__link a span {
  margin: 0px 0.5rem;
  display: flex;
  align-items: center;
}

.page_title .page__link a:nth-last-child(1) {
  color: var(--color-primary);
}


@media only screen and (max-width: 992px) {
  .dnone {
    display: none !important;
  }
}
.partner__container {
    margin-top: 8rem !important;
}

.partner__container h3 {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

.partner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    gap: 1.5rem;
    place-items: center;
}

.partnerImg {
    padding: 1rem 0rem;
}

.partnerImg img {
    width: 15rem;
    height: 5rem;
    margin: 0 auto;
    object-fit: contain;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.partnerImg img:hover {
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
}

@media only screen and (max-width: 1024px) {
    .partner__container {
        margin-top: 2rem !important;
    }

    .partner__container h3 {
        margin-bottom: 1rem;
    }
}
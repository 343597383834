.header{
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.everyCar{
    padding:3rem 0rem;
    text-align: center;
}

.everyCar h3{
  width: max-content;
  margin:0px auto;
}

.everyCar .items{
    margin-top: 5.6rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20.8rem, 22rem));
    justify-content: center;
    gap:3rem;
}

.everyCar .item{
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    width: 20.8rem;
    height: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.everyCar .item img{
    position: absolute;
    left:2.4rem;
    top:-2.5rem;
    z-index: 9;
    width: auto;
    object-fit: cover;
}

.everyCar .item:nth-last-child(1) img{
    top:-3rem;
    left: -1rem;
    width: 11rem;
    height: 6rem;
    
}
.everyCar .item h4{
    font-weight: 600;
    font-size: 18px;
    color: #434242;
}

.banner{
    margin-top: 6rem;
    width: 100%;
    height: 284px;
}


@media only screen and (max-width: 1024px) {
   
    .mobilFilter{
        display: block;
        background: var(--bg-grey);
        padding:2rem 0rem;
    }
    .filterFlex{
        display: flex;
        justify-content: center;
        gap:2rem;
        margin-bottom: 2rem;
    }
    .commonFilter{
        display: grid ;
        grid-template-columns: repeat(2, 1fr);
        gap:1rem;
    }
    .commonFilter .btn__filter__search{
        height: 38px ;
    }

    .banner{
        margin-top: 2rem;
        height: auto;
        padding: 2.5rem 0rem;
    }
}


@media only screen and (max-width: 600px) {
 
    .everyCar .items{
        grid-template-columns: 1fr 1fr;
        row-gap: 3rem;
        column-gap: 1.5rem;
    }
    .everyCar .item{
        width: auto;
    }
    .banner{
        padding: 1rem 0rem;
    }
}
.mobilFilter {
    display: none;
}


@media only screen and (max-width: 1024px) {

    .mobilFilter {
        display: block;
        background: var(--bg-grey);
        padding: 2rem 0rem;
    }

    .mobilFilter {
        display: block;
        background: var(--bg-grey);
        padding: 2rem 0rem;
    }

    .mobilFilter button {
        font-family: 'Roboto', sans-serif !important;
        font-size: 1.4rem !important;
    }

    .commonFilter {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        /* gap: 1rem; */
    }

    .commonFilter div:first-child {
        grid-column: span 1;
    }

    .commonFilter .btn__filter__search {
        width: calc(100% - 5rem);
        height: 38px;
        border-radius: 2px !important;
        -webkit-border-radius: 2px !important;
        -moz-border-radius: 2px !important;
        -ms-border-radius: 2px !important;
        -o-border-radius: 2px !important;
    }

    .filterBtnItems {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-column: span 1;
    }

    .clearBtnSelect {
        background: #D4252A;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 2px;
        color: var(--color-white);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}
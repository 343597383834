.main__img,
.cards,
.card__items,
.detail_desc,
.detail_menu {
  display: grid;
  place-items: center center;
  gap: 2rem;
  position: relative;
}

.main__img h5 {
  position: absolute;
  font-size: 3.6rem;
  font-weight: 900;
  color: #fff;
}

.main__img img {
  height: 20rem;
}

.main__img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 14, 14, 0.5);
}

.car__filter__container {
  padding: 2rem 0 !important;
}

.car__selectItem {
  width: 7.4rem !important;
  height: 3.8rem;
  border-radius: 4px;
}

.selectItem2 {
  width: 17.2rem;
}

.car__filter__container input {
  border: 1px solid #cccccc;
  padding-left: 1rem;
}

.cards {
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  margin: 2rem 0 !important;
}

.card__items {
  border: 1px solid rgba(208, 208, 208, 1);
  padding: 1rem;
  border-radius: 5px;
  height: 100%;
}

.card__head {
  border: 2px solid #000;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0.3rem;
  border-radius: 6px;
}

.card__head p {
  display: grid;
  place-items: center center;
}

.card__head p span {
  color: #000;
  font-size: 0.9rem;
  font-weight: 800;
}

.card__head h3 {
  width: 100%;
  font-size: 2.2rem;
  font-weight: 700;
  color: #000;
}

.card__head_detail {
  width: 37rem;
  height: 100%;
  border:4px solid #000;
}

.card__head_detail p {
  gap: 2.5rem !important;
  flex-direction: column;
}

.card__head_detail p span {
  font-size: 2rem;
}

.card__head_detail h3 {
  font-size: 5.5rem;
  padding-left: 1rem;
}

.card__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid rgba(208, 208, 208, 1);
}

.card__body span {
  text-align: end;
}

.card__body h4 {
  color: #181818;
  grid-column: 1 / -1;
}

.select__class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lnone {
  display: none;
}

.detail_desc {
  grid-template-columns: repeat(5, 1fr);
  margin: 3rem 0 6rem;
  gap: 1rem;
}

.detail_desc .card_parts,
.detail_desc h4 {
  background: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 5px;
  height: 8rem;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
}

.detail_desc h4 {
  flex-direction: row;
  align-items: center;
}

.detail_desc p {
  display: flex;
  gap: 1rem;
}

.head,
.lnone_detail {
  display: none;
}

.cards__sklet{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  gap:2rem;
}

@media only screen and (max-width: 1050px) {
  .car__filter__container {
    display: flex !important;
  }

  .detail_desc {
    grid-template-columns: repeat(auto-fill, minmax(26rem, 1fr));
  }

  .detail_desc .card__head_detail {
    width: 100% !important;
  }

  .card__head_detail p {
    gap: 2.4rem;
  }

  .card__head_detail p span {
    font-size: 2rem;
  }

  .card__head_detail h3 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 992px) {
  .lnone {
    display: block;
    position: absolute;
    left: 0;
    margin: 3rem;
    top: 0;
    font-size: 1.3rem;
  }

  .lnone_detail {
    display: block;
    color: #181818;
    font-size: 1.6rem;
    position: absolute;
  }

  .head {
    display: block;
    text-align: center;
    margin-top: 3rem;
    font-size: 1.6rem;
  }

  .card__head_detail h3 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .main__img h5 {
    font-size: 2.6rem;
  }

  .main__img img {
    height: 15rem;
  }

  .car__filter__container {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    place-items: center;
  }

  .selectfirst {
    grid-column: 1 / -1;
    width: 100% !important;
  }

  .car__selectItem {
    width: 100% !important;
  }
}

@media only screen and (max-width: 568px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap:1rem;
  }
  .detail_desc .card__head_detail {
    width: 90% !important;
  }

  .main__img h5 {
    font-size: 2rem;
  }

  .common_card h2 {
    font-size: 1.6rem;
  }

  .detail_desc {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr)) !important;
  }

  .detail_desc .card_parts,
  .detail_desc h4 {
    padding: 1rem;
    gap: 0.5rem;
  }

  .card_part_1 p {
    font-size: 1rem;
  }

  .detail_desc .card__head_detail {
    place-items: center center;
    grid-column: 1 / -1;
    margin-bottom: 2rem;
    height: auto;
  }

  .card__head {
    width: 80%;
  }

  .card__head h3 {
    font-size: 1.8rem;
  }

  .card__head_detail h3 {
    font-size: 4rem;
  }

  .card__body h4 {
    font-size: 1.4rem;
  }
}


@media only screen and (max-width: 440px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
  .card__head {
    width: 90%;
  }
  .card__head h3 {
    font-size: 1.7rem;
  }
  .card__head_detail h3 {
    font-size: 4rem;
  }

  .detail_desc {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr)) !important;
  }

}


@media only screen and (max-width: 380px) {

  .car__filter__container button {
    margin-left: auto;
  }
  .card__head {
    width: 100%;
  }

  .detail_desc .card__head_detail {
    width: 100% !important;
  }

  .detail_desc div,
  .detail_desc h4 {
    align-items: center !important;
  }

  .cards {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  }

  .card__head_detail h3 {
    font-size: 4rem;
    text-align: center;
  }
}
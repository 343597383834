.dealers__cards{
    margin-top: 2.6rem !important;
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(19rem , 1fr));
    gap:1.5rem;
    row-gap: 3rem;
}

.head,
  .lnone {
    display: none;
  }



@media only screen and (max-width:1024px) {
    .dealers__cards{
        grid-template-columns: repeat(auto-fill , minmax(17rem , 1fr));
    }

    .lnone{
        display: block;
        color: #181818;
        position: absolute;
      }
    
      .head {
        display: block;
        text-align: center;
        margin-top: 3rem;
        font-size: 1.6rem;
      }
  }

  @media only screen and (max-width:600px) {
    .dealers__cards{
        grid-template-columns: repeat(auto-fill , minmax(15rem , 1fr));
    }
  }  
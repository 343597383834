.detail__container {
    margin-top: 3.2rem !important;
    /* display: flex; */
    /* display: grid; */
    /* grid-template-columns: 1fr 3fr; */
    /* gap: 3rem; */
}

.detail_content_sect {
    display: flex;
    /* flex-wrap: wrap; */
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: 1fr 3fr; */
    gap: 3rem;
}

.auto_detail_head_btns {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
}

/* .product_selected::before {
    content: "";
    display: block;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/herat_line.svg");
} */

.product_selected.selected::before {
    background-image: url("../../assets/icons/hearted.svg");
}

.product_selected {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    width: auto;
    min-height: 2.4rem;
    padding-left: 3.2rem;
    position: relative;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    cursor: pointer;
}

.product_complain::before {
    content: "";
    display: block;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/complain.svg");
}

.auto_detail_head_btns .product_complain {
    display: inline-flex;
    align-items: center;
    margin-left: 4.8rem;
    width: auto;
    min-height: 2.4rem;
    padding-left: 3.2rem;
    position: relative;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    cursor: pointer;
}

/* Update */
.auto_detail_head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2.4rem;
}

.auto_detail_head h3 {
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: #000;
}

.btn_anc_sect {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 24px;
}

.btn_anc_item {
    display: inline-flex;
    width: auto;
    margin-right: 16px;
    text-decoration: underline;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #757575;
    cursor: pointer;
}

.hide_items {
    display: flex;
    align-items: center;
}

.auto_work_time {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 32px;
    margin-bottom: 12px;
    gap: 16px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000;
}

.auto_sp_icon svg path {
    fill: #757575;
}

.auto_sp_icon svg {
    display: block;
    width: 100%;
    height: 100%;
}

.auto_sp_icon {
    display: block;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
}

/* Update */
.detialText {
    display: block !important;
    grid-template-columns: 3fr 1fr;
    gap: 1.5rem;
    row-gap: 1.5rem;
}

.detialText table td {
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.oem__date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}

.auto_desc_conent {
    display: block;
    width: 100%;
    margin: 5.6rem 0;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #ECECEC;
}

.auto_desc_conent .desctiption {
    position: relative;
    height: 12rem;
    overflow: hidden;
}

.auto_desc_conent.show .desctiption {
    height: auto;
}

.auto_desc_conent.show .desctiption::before {
    display: none;
}

.auto_desc_conent .desctiption::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.auto_desc_more_item {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 1.2rem;
}

.auto_desc_conent.show .auto_desc_more_item {
    display: none !important;
}

.auto_desc_more {
    display: inline-flex;
    width: auto;
    text-decoration: underline;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #0972AC;
    cursor: pointer;
}

.desctiption p {
    color: #434242;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.detialText h3 {
    margin-bottom: 0.8rem;
    font-weight: 500;
    font-size: 25px;
    color: var(--color-black);
}

.detialText strong {
    text-align: center;
}



.detail_flex {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    border-right: 1px solid #181818;
}

.detail_flex2 {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.tableDetail {
    width: 100%;
    border-collapse: collapse;
    margin-top: 4rem;
}

.tableDetail td,
.tableDetail th {
    text-align: center;
    color: var(--color-black);
}

.tableDetail td a {
    color: #737373;
}

.tableDetail thead {
    background: #F3F3F3;
    border-collapse: collapse;
}

.tableDetail thead th {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.tableDetail td {
    font-weight: 400;
    font-size: 12px;
    padding: 5px 0px;
    color: #737373;
}

.tableDetail.tableDetailAutosalon {
    display: table !important;
}


.tableDetail.tableDetailAutosalon td {
    padding: 0.8rem 0;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    line-height: 150%;
    border-bottom: 1px solid #D7D7D7;
}

.tableDetail.tableDetailAutosalon td:first-child {
    color: #434242;
    font-weight: 400;
}

.tableDetail.tableDetailAutosalon td:last-child {
    color: #000;
    font-weight: 500;
}

.utilitiesItems {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
    margin-top: 56px;
}


.utilitiesItems li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ECECEC;
    padding: 7px 16px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    word-break: break-word;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.specialItems {
    display: block;
    width: 100%;
    margin: 56px 0 0 0;
    column-count: 2;
    column-gap: 16px;
}


.specialItems li {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 4px;
}

.specialName {
    display: inline-flex;
    align-items: center;
    width: 40%;
    float: left;
    min-height: 32px;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #757575;
    word-break: break-word;
}

.specialValue {
    display: inline-flex;
    align-items: center;
    width: 60%;
    float: left;
    min-height: 32px;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000;
    word-break: break-word;
}


.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    margin: 2.4rem 0 0 0;
}

.buttons a {
    width: 100%;
    justify-content: flex-start;
    height: 4rem;
    padding: 1rem;
}

/* .buttons :first-child {
    height: 3.8rem;
    display: flex;
    gap: 1.5rem;
    font-family: 'Roboto', sans-serif;
    border-radius: 3px !important;
    padding: 0;
    font-weight: 400;
} */

.btn__common_call {
    height: 3.2rem;
    font-family: 'Roboto', sans-serif;
    width: 14.9rem;
}

.btn_call {
    width: 100%;
    justify-content: center !important;
    height: 4.8rem;
    gap: 0.8rem;
    padding: 0.8rem;
    background-color: white;
    border: 1px solid #D8D8D8 !important;
    color: #000 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
}

.btn_call:hover svg path {
    fill: #fff;
}

.btn_call:hover {
    border-color: #68A834 !important;
    background-color: #68A834 !important;
    color: #fff !important;
}

.buttons a {
    color: #545454;
}

.detail_slide_sect {
    width: 100%;
    max-width: calc(100% - 480px);
    padding-right: 3.2rem;
}

.detailRight {
    width: 100%;
    max-width: 480px;
}

.detailInfo {
    width: 100%;
    margin-top: 5px;
    padding: 2.4rem;
    border: 1px solid #B7B7B7;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.detail_row {
    display: flex;
    justify-content: space-between;
    /* flex-direction: column; */
    gap: 1.3rem;
    row-gap: 1.5rem;
}

.detailMap .top_detail_row {
    margin: 1.6rem 0 !important;
}

.detailMap .top_detail {
    display: flex;
    width: 100%;
    border: none;
    margin: 0.4rem 0;
}

.detailMap button {
    padding: 1rem 2rem !important;
    font-size: 14px;
}

.detailMap p a {
    display: flex;
    gap: 1.5rem;
    font-weight: 400;
    font-size: 12px;
    color: var(--color-black);
    line-height: 2px;

}

.detailMap p a svg {
    color: #BBBBBB;
    font-size: 1.6rem;
}

.showMap {
    font-size: 1.2rem;
    color: var(--color-primary);
}

.complaint {
    font-size: 1.2rem;
    color: #434242;
    display: flex;
    gap: 0.6rem;
}

.complaint svg {
    font-size: 1.8rem;
    color: #FE0000;
}

.discount {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-decoration: line-through;
    color: #FF0000;
}

.d_flex {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 3px;
}

.d_flex h3 {
    gap: 1rem;
    display: flex;
    align-items: center;
}

.detial_p {
    line-height: 2.7rem;
}

.price {
    width: 100%;
    margin-right: auto;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    color: #000;
    display: none;
}

.d_flex .price {
    display: inline-block;
}

.seeMore {
    padding: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 3rem;
}

.seeMore a {
    font-weight: 500;
    font-size: 13px;
    color: #181818;
}

.seeMore a:nth-last-child(1) {
    color: #4b4b4b;
}

.banerDetail {
    padding-top: 6rem;
}

.banerDetail img {
    height: 17.5rem;
}

.mobilTable {
    display: none;
}

.discountDemo {
    display: none;
}

.logoShop {
    display: flex;
    align-items: center;
    margin: 2.4rem auto 0 0;
    /* border: 1px solid #D8D8D8; */
    /* border-radius: 2px; */
    /* padding: 0.5rem 1.5rem; */
}

.logoShop img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    margin-right: 1.6rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.logoShop span {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
}

.auto_about_sect {
    display: block;
    width: 100%;
    margin: 5.6rem 0;
    padding: 1.6rem 0;
    border-top: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
}

.auto_about_title {
    display: block;
    width: 100%;
    margin-bottom: 1.6rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #434242;
    word-break: break-word;
}

.auto_about_stext {
    display: block;
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #434242;
    word-break: break-word;
}

.top_detail_row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 2rem 0 0 0;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #ECECEC;
}

.top_detail:last-child {
    border: none;
}

.top_mrg .top_detail {
    margin-left: auto;
}

.top_detail {
    display: inline-flex;
    flex-wrap: wrap;
    color: #434242;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 150%;
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #000;
}

.top_detail span {
    font-weight: 400;
    margin-right: 0.8rem;
    color: #757575;
}

.top_detail p {
    color: #434242;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 150%;
}

.top_detail b {
    font-style: 500;
}

.mobile_show {
    display: none;
    /* margin-bottom: 2rem; */
    min-height: 24px;
    font-weight: 500;
    font-size: 25px;
    color: var(--color-black);
    align-items: center;
    position: relative;
    padding-left: 36px;
    cursor: pointer;
}

.mobile_show::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: 9px;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/arw_back_b.svg");
}

.mobile_show_right {
    display: none;
    margin: 1.2rem auto;
    font-weight: 500;
    font-size: 25px;
    color: var(--color-black);
    align-items: center;
    position: relative;
    padding-right: 36px;
    cursor: pointer;
}

.mobile_show_right::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/arw_right_b.svg");
}

.mobile_show_sect {
    display: none;
}

.mobile_show_flex_item {
    display: none;
}

.sect_flex_mob {
    display: none;
}

.mobile_marka {
    display: none;
    margin: -12px 0 40px 0;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
}

@media only screen and (max-width: 1280px) {
    .detail__container {
        margin-top: 3rem !important;
        grid-template-columns: 1fr 1fr;
    }

    .detialText {
        grid-template-columns: 1fr;
    }

    .buttons {
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin: 1.4rem 0;
    }
}


@media only screen and (max-width: 1024px) {
    .detail__container {
        margin-top: 1.6rem !important;
        grid-template-columns: 1fr;
        gap: 1rem;
        flex-direction: column;
    }

    .detail_slide_sect {
        width: 100%;
    }

    .detailInfo {
        padding: 0rem;
        border: none;
    }

    .detail_row_mob {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .top_detail_row {
        width: auto;
        align-items: center;
        margin: 1rem auto 1rem 0;
    }

    .sect_flex_mob {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: row-reverse;
    }

    .mobile_show_right {
        display: flex;
    }

    .mobile_show {
        display: flex;
    }

    .mobile_show_flex_item {
        display: flex;
    }

    .mobile_show_sect {
        display: block !important;
        margin: 2rem auto;
    }

    .mobile_hide {
        display: none !important;
    }

    .tableDetail {
        display: none;
    }

    .price {
        display: inline-block;
        color: #D4252A;
    }

    .detialText .discount {
        display: block;
    }

    .d_flex .price {
        display: none;
    }

    .d_flex .discount {
        display: none;
    }

    .mobilTable {
        display: flex;
        margin-top: 5rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 2rem;
    }

    .detialText {
        gap: 1.5rem;
    }

    .mobilTable li a,
    .mobilTable li span {
        font-weight: 400;
        font-size: 12px;
        color: #181818;
    }

    .mobilTable ul li {
        list-style: disc;
        margin-bottom: 0.3rem;
    }

    .mobilTable h4 {
        margin: 0rem auto 1rem;
        font-size: 14px;
        text-align: center;
    }

    /* mobile auto detail */
    .detail_content_sect {
        flex-wrap: wrap;
        gap: 0;
    }

    .detail_slide_sect {
        width: 100%;
        max-width: 100%;
        padding: 0 0 1.6rem 0;
    }

    .detailRight {
        width: 100%;
        max-width: 100%;
    }

    /* .slide_all_show .detailSlider {
            display: block !important;
        } */
    .logoShop {
        margin: 0;
    }

    .logoShop img {
        width: 5.6rem;
        height: 5.6rem;
        object-fit: cover;
        margin-right: 1.6rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }

    .detial_p {
        min-width: 0;
        /* width: 30%; */
        margin-left: 0;
        display: grid;
        place-content: flex-start flex-end;
        color: #D4252A;
    }

    .specialItems {
        margin: 0px 0 0 0;
        column-count: 1;
        column-gap: 16px;
    }

    .mobile_marka {
        display: block;
    }

    .utilitiesItems {
        margin-top: 24px;
    }

    .auto_desc_conent {
        margin: 2.4rem 0 0 0;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #ECECEC;
    }

    .top_mrg {
        justify-content: space-between;
    }

    .top_mrg .top_detail {
        margin-left: 0;
    }

    /* mobile auto detail */
}


@media only screen and (max-width: 767px) {
    .detail__container {
        margin-top: 1.6rem !important;
        grid-template-columns: 1fr;
        gap: 1rem;
        flex-direction: column;
    }

    .detail_slide_sect {
        width: 100%;
    }

    .detailInfo {
        padding: 0rem;
        border: none;
    }

    .banerDetail img {
        height: 13.8rem;
    }

    .detialText h3 {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        gap: 2rem;
    }

    .green_btn,
    .btn_call {
        width: 100%;
    }

    .detial_p {
        min-width: 120px;
        /* width: 30%; */
        margin-left: auto;
        display: grid;
        place-content: flex-start flex-end;
    }

    .tableDetail.tableDetailAutosalon td:last-child {
        color: #000;
        font-weight: 500;
        text-align: right;
    }

    .buttons {
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin: 0 0 1.4rem 0;
    }

    /* mobile auto detail */
    .detail_content_sect {
        flex-wrap: wrap;
        gap: 0;
    }

    .detail_slide_sect {
        width: 100%;
        max-width: 100%;
        padding: 0 0 1.6rem 0;
    }

    .detailRight {
        width: 100%;
        max-width: 100%;
    }

    /* .slide_all_show .detailSlider {
        display: block !important;
    } */
    .logoShop {
        margin: 0;
    }

    .logoShop img {
        width: 5.6rem;
        height: 5.6rem;
        object-fit: cover;
        margin-right: 1.6rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }

    .detial_p {
        min-width: 0;
        /* width: 30%; */
        margin-left: 0;
        display: grid;
        place-content: flex-start flex-end;
        color: #D4252A;
    }

    .specialItems {
        margin: 0px 0 0 0;
        column-count: 1;
        column-gap: 16px;
    }

    .mobile_marka {
        display: block;
    }

    .utilitiesItems {
        margin-top: 24px;
    }

    .auto_desc_conent {
        margin: 2.4rem 0 0 0;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid #ECECEC;
    }

    .top_mrg {
        justify-content: space-between;
    }

    .top_mrg .top_detail {
        margin-left: 0;
    }

    /* mobile auto detail */
}

@media only screen and (max-width: 400px) {

    .detialText h3 {
        justify-content: space-between;
        font-size: 1.8rem;

    }

    .logoShop {
        margin-right: auto;
        margin-left: 0;
    }

    .green_btn,
    .btn_call {
        width: 100%;
    }

}
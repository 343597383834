.loading{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.loader {
  overflow: hidden;
  position: relative;
}

.wheel {
  fill: rgb(0, 0, 0);
  font-size: 100px;
  width: 100px;
  height: 100px;
  border-radius: 6em;
  text-align: center;
  animation: rolling 1s linear infinite;
}

#rim {
  fill: rgb(0, 0, 0);
}

#tire {
  fill: rgb(0, 0, 0);
}

.road {
  position: absolute;
  margin-top: -4px;
  width: 100%;
  height: 10px;
  border-radius: 5em;
  background-color: rgb(0, 0, 0);
  animation: road-move 2s linear infinite;
}

@keyframes rolling {
  to {
    transform: rotate(-360deg);
  }
}

@keyframes road-move {
  from {
    transform: translateX(-140%);
  }

  to {
    transform: translateX(100%);
  }
}

.detialText {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    row-gap: 2.4rem;
}

.detialText table td {
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.oem__date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}


.detialText h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    font-weight: 400;
    font-size: 20px;
    color: var(--color-black);
}

.detialText strong {
    text-align: center;
}

.detail_flex {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    border-right: 1px solid #181818;
    padding-right: 1.6rem;
}

.detail_flex2 {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.tableDetail {
    width: 100%;
    border-collapse: collapse;
    margin-top: 4rem;
}

.tableDetail td,
.tableDetail th {
    text-align: center;
    color: var(--color-black);
}

.tableDetail td a {
    color: #737373;
}

.tableDetail thead {
    background: #F3F3F3;
    border-collapse: collapse;
}

.tableDetail thead th {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    padding: 0.8rem;
}

.tableDetail td {
    font-weight: 400;
    font-size: 12px;
    padding: 5px 0px;
    color: #737373;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.4rem;
}

.buttons a {
    padding-bottom: 1rem !important;
}

.buttons :first-child {
    height: 3.8rem;
    display: flex;
    gap: 1.5rem;
    font-family: 'Roboto', sans-serif;
    border-radius: 3px !important;
    padding: 0;
    /* padding-bottom: 1rem; */
    font-weight: 400;

}

.btn__common_call {
    height: 3.8rem;
    font-family: 'Roboto', sans-serif;
    width: 14.9rem;
}

.btn_call {
    background-color: white;
    border: 1px solid #D8D8D8 !important;
    border-radius: 3px !important;
    color: #68A834 !important;

}

.buttons a {
    color: #545454;
}

.detailMap button {
    padding: 1rem 2rem !important;
    font-size: 14px;
}

.detailMap p a {
    display: flex;
    gap: 0.8rem;
    font-weight: 400;
    font-size: 12px;
    color: var(--color-black);
    line-height: 2px;

}

.detailMap p a svg {
    color: #BBBBBB;
    font-size: 1.6rem;
}

.showMap {
    font-size: 1.2rem;
    color: var(--color-primary);
}

.complaint {
    font-size: 1.2rem;
    color: #434242;
    display: flex;
    gap: 0.6rem;
}

.complaint svg {
    font-size: 1.8rem;
    color: #FE0000;
}

.discount {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-decoration: line-through;
    color: #FF0000;
}

.d_flex {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 3px;
}

.d_flex h3 {
    gap: 1rem;
    display: flex;
    align-items: center;
}

.detial_p {
    line-height: 2.7rem;
}

.price {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #434242;
    display: none;
}

.d_flex .price {
    display: inline-block;
}

.seeMore {
    padding: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 3rem;
}

.seeMore a {
    font-weight: 500;
    font-size: 13px;
    color: #181818;
}

.seeMore a:nth-last-child(1) {
    color: #4b4b4b;
}

.banerDetail {
    padding-top: 6rem;
}

.banerDetail img {
    height: 17.5rem;
}

.mobilTable {
    display: none;
}

.discountDemo {
    display: none;
}

.logoShop {
    margin-left: auto;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    padding: 0.5rem 1.5rem;
}

.logoShop img {
    width: 8rem;
    height: 3.7rem;
    object-fit: contain;

}

@media only screen and (max-width: 1280px) {
    .detail__container {
        margin-top: 3rem !important;
        grid-template-columns: 1fr 1fr;
    }

    .detialText {
        grid-template-columns: 1fr;
    }
}


@media only screen and (max-width: 1024px) {
    .tableDetail {
        display: none;
    }

    .price {
        display: inline-block;
    }

    .detialText .discount {
        display: block;
    }

    .d_flex .price {
        display: none;
    }

    .d_flex .discount {
        display: none;
    }

    .mobilTable {
        display: flex;
        margin-top: 2.4rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 2rem;
    }

    .detialText {
        gap: 1.5rem;
    }

    .mobilTable li a,
    .mobilTable li span {
        font-weight: 400;
        font-size: 12px;
        color: #181818;
    }

    .mobilTable ul li {
        list-style: disc;
        margin-bottom: 0.3rem;
    }

    .mobilTable h4 {
        margin: 0rem auto 1rem;
        font-size: 14px;
        text-align: center;
    }
}


@media only screen and (max-width: 767px) {
    .detail__container {
        margin-top: 1rem !important;
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .detailInfo {
        padding-top: 0rem
    }

    .banerDetail img {
        height: 13.8rem;
    }

    .detialText h3 {
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        gap: 2rem;
    }

    .green_btn,
    .btn_call {
        width: 100%;
    }

    .detial_p {
        width: 30%;
        margin-left: auto;
        display: grid;
        place-content: flex-start flex-end;
    }
}

@media only screen and (max-width: 400px) {

    .detialText h3 {
        justify-content: space-between;
        font-size: 1.8rem;

    }

    .logoShop {
        margin-right: auto;
        margin-left: 0;
    }

    .green_btn,
    .btn_call {
        width: 100%;
    }

    .detial_p {
        width: 30%;
        margin-left: auto;
        display: grid;
        place-content: flex-start flex-end;
    }
}

/* !Detail update */
.detail__container {
    margin-top: 4rem !important;
    display: grid;
    grid-template-columns: 3fr 1fr;
    /* grid-template-columns: 1fr 2fr 1fr; */
    gap: 3rem;
    align-items: flex-start;
}


.detail_left {
    display: block;
    width: 100%;
    /* max-width: calc(100% - 312px); */
}

.detail_right {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.detailMap_grid {
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    align-items: flex-end;
    gap: 3rem;
    width: 100%;
}

.detailMap .map {
    height: 18rem !important;
}

.detailMap .map_wrap {
    margin-top: 1.4rem !important;
}

.detail_itm_content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
    width: 100%;
}

.detail_company_itm:last-child {
    border: none;
}

.detail_company_itm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 3.2rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #D7D7D7;
}

.detail_company_title {
    display: block;
    width: 100%;
    margin-bottom: 1.6rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
}

.detail_company_itm .logoShop {
    margin: 0 auto 0 0;
}

.detail_company_row {
    display: flex;
    width: 100%;
    gap: 0.8rem;
}

.detail_transport::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 3.2rem;
    height: 3.2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
}

.courier::before {
    background-image: url("../../assets/icons/courier_icon.svg");
}

.stay_by::before {
    background-image: url("../../assets/icons/stay_by_icon.svg");
}

.express_by::before {
    background-image: url("../../assets/icons/express_by_icon.svg");
}

.az_post_by::before {
    background-image: url("../../assets/icons/az_post_by_icon.svg");
}

.cash_by::before {
    background-image: url("../../assets/icons/cash_by_icon.svg");
}

.card_by::before {
    background-image: url("../../assets/icons/card_by_icon.svg");
}

.credit_by::before {
    background-image: url("../../assets/icons/credit_by_icon.svg");
}

.detail_transport {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 2.4rem;
    padding-left: 44px;
}

.detail_transport:last-child {
    margin-bottom: 0rem;
}

.dtl_trn_title {
    display: block;
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: #000;
    margin-bottom: 0.4rem;
}

.dtl_trn_info {
    display: block;
    width: 100%;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: #757575;
}

.rating_item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
}

.star_item {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.star_item_title {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    margin-left: 1.2rem;
}

.star_svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/star_rating.svg");
}

.star_svg {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/star_rating.svg");
}

.star_0 .star_svg:nth-child(0),
.star_1 .star_svg:nth-child(1),
.star_2 .star_svg:nth-child(-n+2),
.star_3 .star_svg:nth-child(-n+3),
.star_4 .star_svg:nth-child(-n+4),
.star_5 .star_svg:nth-child(-n+5) {
    background-image: url("../../assets/icons/stared_rating.svg");
}

/* .product_selected::before {
    content: "";
    display: block;
    align-items: center;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/icons/heart.svg");
} */

.product_selected.selected::before {
    background-image: url("../../assets/icons/hearted.svg");
}

.product_selected {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    width: auto;
    min-height: 2.4rem;
    position: relative;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    cursor: pointer;
}

.product_selected_row {
    display: flex;
    align-items: center;
    float: right;
    justify-content: flex-end;
    margin: auto 0 0 auto;
}


.product_selected_row .rating_item {
    display: none;
}

.detailImage {
    display: block;
    width: 100%;
    height: 18rem;
}

.detailImage img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.deail_slide_icons {
    display: block;
    position: relative;
}

.deail_slide_icons_mob {
    display: none;
    position: relative;
}

.social_items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    top: 20rem;
    padding: 0.8rem 1.6rem;
    gap: 0.8rem;
}

.deail_slide_icons_mob .social_items {
    top: 1rem;
}

.deail_slide_icons_mob .btn_social_icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
}

.btn_social_icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 99;
}

.btn_fb {
    background-image: url("../../assets/icons/dt_fb.svg");
}

.btn_insta {
    background-image: url("../../assets/icons/dt_insta.svg");
}

.btn_lnk {
    background-image: url("../../assets/icons/dt_lnk.svg");
}

.btn_wp {
    background-image: url("../../assets/icons/dt_wp.svg");
}

.green_btn,
.btn_call {
    width: 100%;
}

.detailMap_grid .btnFlexTop {
    /* height: 4rem; */
    margin: 0;
}

.detailMap p a svg path {
    fill: #434242 !important;
}

.call__number {
    display: flex;
    align-items: center;
    float: right;
}

.call__number a {
    display: flex;
    gap: 0.8rem;
    text-align: center;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: #000;
}


/* technics section */
.detail_technics {
    display: block;
    width: 100%;
    margin-top: 4.8rem;
}

.detail_technics_head {
    display: block;
    width: 100%;
}

.detail_technics_title {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    color: #000;
    margin-bottom: 1.6rem;
}

.tec_tab_container {
    display: block;
    width: 100%;
}

.tec_body {
    display: block;
    width: 100%;
}

.tec_body_items {
    display: block;
    width: 100%;
    padding-bottom: 2.4rem;
    margin-bottom: 3.2rem;
    border-bottom: 1px solid #D7D7D7;
}

.tec_body_items .rating_item {
    display: flex !important;
    padding: 0 !important;
}

.tec_body_items .star_svg {
    width: 2rem;
    height: 2rem;
}

.tec_body_title {
    display: block;
    width: 100%;
    margin-bottom: 1.6rem;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
}

.tec_body_info {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #434242;
}



.tec_tab_btn {
    display: flex;
    width: 100%;
    margin-bottom: 2.4rem;
    overflow: auto;
    flex-wrap: nowrap;
}

.tec_tab_btn li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #434242;
    margin-right: 2.4rem;
    white-space: nowrap;
    cursor: pointer;
}

.active_tab {
    color: #639A46 !important;
}

.tec_rows:nth-child(2n+1) {
    background: #F3F3F3;
}

.tec_rows {
    display: flex;
    width: 100%;
    gap: 0.8rem;
}

.tec_rows_name,
.tec_rows_val {
    width: 50%;
    padding: 1rem 1.6rem;
}

/* technics section */

@media only screen and (max-width: 1024px) {
    .detail__container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.4rem !important;
        grid-template-columns: 1fr;
        gap: 1.6rem;
    }


    .detail_left {
        display: block;
        width: 100%;
        max-width: 100%;
    }

    .detail_right {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .detail_itm_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.6rem;
        width: 100%;
    }

    .product_selected_row {
        justify-content: space-between;
        width: 100%;
    }

    .rating_item {
        display: none;
    }

    .product_selected_row .rating_item {
        display: flex;
        width: auto;
    }

    .star_item_title {
        font-size: 1.1rem;
    }

    .product_selected {
        padding-left: 2.4rem;
        font-size: 1.1rem;
    }

    .product_selected::before {
        width: 16px;
        height: 16px;
    }

    .detailMap_grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 1.6rem;
    }

    .detailImage {
        display: none;
    }

    .buttons {
        gap: 1rem;
        margin-bottom: 0rem;
    }

    .detail_company_itm:last-child {
        margin-bottom: 0rem;
        padding-bottom: 0rem;
    }

    .detailInfo {
        width: 100%;
    }

    .tec_tab_btn {
        padding: 1.8rem 0;
        border-top: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;
    }

}

@media only screen and (max-width: 560px) {

    .deail_slide_icons {
        display: none;
    }

    .deail_slide_icons_mob {
        display: block;
    }

}



/* !Detail update */
.head,
.headcolor,
.greenhead {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5.6rem 0 3.2rem;
  text-align: center;
  font-family: "Roboto";
  font-size: 3.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
}

.often {
  font-size: 20px;
  font-weight: 500;
  color: #c7c7c7;
  margin-bottom: 2rem;
}


:is(.contents, .right__part) p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 2rem;
  color: #000;
  letter-spacing: 0.3px;
  line-height: 1.5;
}

.right__part h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 1.3rem;
}


.comman_wrapper {
  display: grid;
  grid-template-columns: 31rem 4fr;
  column-gap: 4rem;
}

.comman_wrapper2 {
  display: grid;
  grid-template-columns: 4fr 2fr;
  column-gap: 3rem;
}

.comman_wrapper_revers {
  grid-template-columns: 4fr 31rem;
}

.comman_wrapper_revers .left {
  order: 2;
}

.comman_wrapper_revers .right__part {
  order: 1;
}

.left {
  max-height: 65rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 10px;
  padding: 3.2rem;
  background-color: #fcfcfc;
  gap: 3rem;
}

.term_list {
  padding: 0rem 2rem;

}

.term_list li {
  list-style-type: disc;
  padding: 1rem;
  font-size: 14px;
  font-weight: 300;
  color: #181818;
  letter-spacing: 0.3px;
  line-height: 24px;

}

.left h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
}

.left p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

.left div:nth-of-type(1) {
  padding-bottom: 2rem;
  border-bottom: 2px solid #f1f1f1;
}

.part1 {
  display: grid;
  gap: 15px;
}

.part1 :nth-child(1) {
  text-align: center;
}

.contact_form {
  display: grid;
  place-items: center center;
  width: 40%;
  margin: auto;
  gap: 2rem;
  margin-top: 4rem;
}

.form_item {
  width: 100%;
}

.form_item input {
  width: 100%;
  height: 52px;
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding-left: 1.5rem;
  border-left: 3px solid #68a834;
}

.form_item input ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #cccccc;
}

.form_item textarea {
  width: 100%;
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 1.5rem 0 0 1.5rem;
  border-left: 3px solid #68a834;
}

.form_item textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #cccccc;
}

.send_btn {
  margin-left: auto;
  width: 141px;
  height: 45px;
  margin-top: 2rem;
}

.lnone {
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  display: none;
}

.about__list {
  padding-left: 2rem;
}

.about__list li {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin: 1.5rem;
  color: #000;
  letter-spacing: 0.3px;
  line-height: 1.4;
  list-style-type: disc;
}

.content_abt ul,
.content_abt ol {
  display: inline-block;
  width: auto;
  margin: 0 auto 1.6rem auto;
}

.content_abt {
  text-align: center;
}

.content_abt p {
  text-align: center;
  font-family: "Roboto";
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #000;
  letter-spacing: 0.3px;
  line-height: 1.5;
}

.content_abt h3 {
  font-family: "Roboto";
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 1.2rem;
  color: #000;
  letter-spacing: 0.3px;
  line-height: 1.5;
}

.right__part a {
  color: #68a834;
  font-weight: 500;
}

.aboutBg {
  display: block;
  width: 100%;
  height: 30rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../assets/icons/about_bg.png");
}

.collapse_container {
  display: block;
  width: 100%;
}

.collapse_item.active {
  border-color: #639A46;
}

.collapse_item.active::before {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

.collapse_item::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 20px;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../../../assets/icons/arw_drop_g.svg");
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
}

.collapse_item {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 20px 16px 0;
  border-bottom: 1px solid #D7D7D7;
  cursor: pointer;
}

.collapse_item__title {
  display: block;
  width: 100%;
  margin-bottom: 0 !important;
}

.collapse_item__title h3 {
  margin-bottom: 0 !important;
}

.collapse_item__content {
  display: none;
  width: 100%;
  margin-top: 2.4rem;
}

.active .collapse_item__content {
  display: block;
}

@media only screen and (max-width: 992px) {
  .comman_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .comman_wrapper2 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .comman_wrapper2 :last-child {
    order: -1;
  }

  .comman_wrapper_revers .left {
    order: -1;
  }

  .comman_wrapper_revers .right__part {
    order: 0;
  }

  .left {
    display: flex;
    flex-direction: row;
  }

  .left div:nth-of-type(1) {
    padding-right: 2rem;
    border-right: 2px solid #f1f1f1;
    border-bottom: none;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 50%;
    margin: auto;
    gap: 2rem;
  }

  .right__part h3 {
    font-size: 15px;
    font-weight: 400;
    order: -1;
  }

  .right__part p {
    font-size: 13px;
    font-weight: 300;
  }

  .dnone {
    display: none !important;
  }

  .lnone {
    display: flex;
  }
}

@media only screen and (max-width: 700px) {

  .head,
  .greenhead {
    font-size: 24px;
    margin: 3.2rem 0 2.4rem;
  }

  .contents p {
    font-size: 16px;
  }

  .right__part h3 {
    font-size: 15px;
    font-weight: 400;
    order: -1;
  }

  .right__part p {
    font-size: 13px;
    font-weight: 300;
  }

  .left {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: stretch;
  }

  .left h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .left div:nth-of-type(1) {
    padding-right: 1.5rem;
    border-right: 2px solid #f1f1f1;
    border-bottom: none;
    padding-bottom: 0;
  }

  .left div:nth-of-type(2) {
    padding-left: 1.5rem;
  }

  .part1 {
    display: grid;
    gap: 13px;
  }

  .comman_wrapper2 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
  }

  .comman_wrapper2 :last-child {
    order: -1;
  }

  .comman_wrapper_revers .left {
    order: -1;
  }

  .comman_wrapper_revers .right__part {
    order: 0;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 60%;
    margin: auto;
    gap: 2rem;
  }

  .aboutBg {
    height: 12rem;
  }
}

@media only screen and (max-width: 600px) {

  .head,
  .greenhead {
    font-size: 24px;
    margin: 3.2rem 0 2.4rem;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 95%;
    margin: auto;
    gap: 2rem;
  }

  .send_btn {
    width: 100%;
  }

  .comman_wrapper2 :nth-of-type(2) {
    order: -1;
    text-align: center;
  }

  .comman_wrapper_revers .left {
    order: -1;
  }

  .comman_wrapper_revers .right__part {
    order: 0;
  }

  .comman_wrapper_revers .left {
    display: flex;
    flex-direction: column;
  }

}

@media only screen and (max-width: 361px) {

  .head,
  .greenhead {
    font-size: 24px;
    margin: 3.2rem 0 2.4rem;
  }

  .contents p {
    font-size: 16px;
  }


  .left {
    display: flex;
    flex-direction: column;
  }

  .left div:nth-of-type(1) {
    padding-bottom: 1rem;
    border-bottom: 2px solid #f1f1f1;
    border-right: none;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,500;1,700&family=Work+Sans:wght@100&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~video-react/dist/video-react.css';
@import url("./assets/styles/button.css");
@import url("./assets/styles/global.css");
@import url("./assets/styles/service.css");
@import 'react-loading-skeleton/dist/skeleton.css';
@import 'react-toastify/dist/ReactToastify.css';


*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    text-decoration: none ;
    list-style: none;
}

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%; /* 1rem = 10px 10px/16px = 62.5% */
}

:root {
    --color-primary: #68A834;
    --color-gray:#515151;
    --bg-grey:#F3F3F3;
    --bg-gray:#434343;
    --color-black:#181818;
    --color-white:#FFFFFF;
    --bg-gray2:#DEDEDE;

    --transition: all 400ms ease;

    --container-width-lg: 128rem;
    --container-width-md: 92%;
    --container-width-sm: 94%;
}

img {
  width: 100%;
  display: inline-block;
  object-fit: cover;
}

.container{
    max-width: var(--container-width-lg);
    width: 100%;
    margin:0px auto;
}

h3{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
}

h4{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
}

h5{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
}

p{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #545454;
}

.text-sm {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.text-md{
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #181818;
}

.text-title{
  font-weight: 800;
  font-size: 2.5rem;
  color: #434242;
  text-transform: capitalize;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #434242;
}

.activeTab{
  color: #A0A0A0 !important;
  border-left: 3px solid var(--color-primary);
}

.nextButton{
  display: flex;
  justify-content: flex-end;
  padding:4rem 1rem;
}

.activeA{
  font-weight: 500;
  color: #181818 !important;
  border-bottom: 2px solid var(--color-primary);
}

.btnFlex{
  display: flex;
  gap:1rem;
  align-items: center;
  justify-content: space-between;
}
.btnFlexTop{
  margin-top: 1rem;
}

.oemCode{
  width: 26rem;
  height: 3.8rem;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  border: 1px solid #B7B7B7;
  border-right: none;
}

.oemCode input{
  width: 80%;
  margin-left: 1.5rem;
}

.more{
  display: flex;
  justify-content: center;
  padding:3rem 0rem 2rem;
  
}
.more button{
    color: var(--color-primary);
    font-weight: 400;
    font-size: 1.7rem;
    border-bottom: 1px solid var(--color-primary);
    background-color: transparent;
}
.more a{
  color: var(--color-primary);
  font-weight: 400;
  font-size: 1.7rem;
  border-bottom: 1px solid var(--color-primary);
  background-color: transparent;
}

 

  @media only screen and (max-width: 1320px) {
    .container{
      max-width: var(--container-width-md);
    }
  }

  @media only screen and (max-width: 1024px) {
    .activeTab{
      border-left: none;
    }
    .oemCode{
      width: 100%;
      margin-top: 4px;
    }
    .oemCode input{
      width: 90%;
    }
    .oemCode button{
      padding: 1rem 1.5rem;
    }
    .mobilVideo{
      display: none;
    }


  }


  @media only screen and (max-width: 600px) {
    .container{
      max-width: var(--container-width-sm);
    }
    .text-title{
      font-size: 2rem;
      padding-bottom:0.2rem;
    }
  
  }

.main {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.content {
    margin: 0 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 38rem;
    width: 100%;
}

.content h3 {
    font-size: 2rem;
    margin-bottom: 1.6rem;
}

.inputs {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
}

.eye {
    position: absolute;
    top: 6.3rem;
    right: 1.5rem;
    color: #8E8E8E !important;
}

.inputs label {
    color: #8E8E8E;
    font-size: 14px;
    margin: 2.5rem 0 5px 0;
}

.inputs input {
    border: 1px solid #B7B7B7;
    height: 5rem;
    border-radius: 5px;
    padding: 1rem;
}

.submit_btn {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
}

/* !Update register form */
.content {
    max-width: 40rem;
}

.inputs label {
    color: #fff;
    font-size: 15px;
    margin: 2.4rem 0 0.8rem 0;
}

.submit_btn {
    margin-top: 0rem;
}

.dangerText {
    margin-top: 0.4rem;
}

.content h3 {
    font-size: 2.5rem;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .main {
        padding-top: 2.4rem;
        height: auto;
    }

    .content p,
    .contentText {
        color: #7C7C7C;
    }

    .recovery_input label,
    .content h3,
    .inputs label,
    .timer,
    .request_btn {
        color: #181818;
    }
}

/* !Update register form */
.service__container {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.service__container h3 {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
}

.service {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); */
    /* grid-template-columns: repeat(2, 25rem); */
    grid-template-columns: repeat(5, 25rem);
    justify-content: center;
    gap: 5rem;
}

.service article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}

.service article .service__icon {
    height: 15.7rem;
    display: flex;
    align-items: flex-end;
}

.service article p {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #545454;
}

@media only screen and (max-width: 1024px) {
    .service__container {
        margin-top: 2rem !important;
        margin-bottom: 2rem;
    }

    .service {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem
    }

    .service article p {
        font-size: 15px;
    }

    .service article svg {
        height: 12rem;
    }
}


@media only screen and (max-width: 820px) {
    .service {
        gap: 1rem
    }

    .service article {
        position: relative;
        padding: 1.6rem 2.4rem;
        border: 1px solid #B7B7B7;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    .service article .service__icon {
        width: auto;
        height: auto;
    }

    .service article svg {
        width: 4.8rem;
        height: 4.8rem;
    }
}

@media only screen and (max-width: 600px) {
    .service article {
        gap: 1rem;
    }

    .service {
        gap: 1rem
    }

    .service article {
        position: relative;
        padding: 1.6rem 2.4rem;
        border: 1px solid #B7B7B7;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    .service article .service__icon {
        width: auto;
        height: auto;
    }

    .service article svg {
        width: 4.8rem;
        height: 4.8rem;
    }

    .service article p {
        font-size: 11px;
    }

    .service article div {
        height: auto;
    }
}
.tabs {
    width: 100%;
    height: 100%;
    /* border-right: 5px solid #F1F1F1; */
    /* border-right: 1px solid #D7D7D7; */
}

.tabs li {
    margin-bottom: 2.3rem;
}

.tabs a {
    width: 100%;
    padding: 0px 15px;
    display: block;
    color: var(--color-black);
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

.selectItem {
    display: none;
}

@media only screen and (max-width: 820px) {
    .tabs {
        display: none;
    }

    .selectItem {
        display: block;
    }

    .selectItemMobil {
        display: none;
    }
}
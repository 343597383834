.privateInfo__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16rem;
  max-width: 83rem;
}

.privateInfo__container form h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  text-align: left;
  font-family: "Roboto";
  font-size: 21.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #000;
  margin-bottom: 2.4rem;
}

.privateInfo__container form h3 svg {
  color: #ebebeb;
  font-size: 3.5rem;
}

/* .privateForm p {
      margin-top: 8px;
      position: absolute;
      top: 60px;
  } */

.checkbox-wrapper-4 * {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
  width: max-content !important;
}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}

.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  strokewidth: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #639a46;
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #639a46;
  border-color: #639a46;
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

.checkbox {
  display: flex;
  gap: 10px;
}
.privateForm {
  position: relative;
  margin-bottom: 2rem;
  position: relative;
}

.privateFormSelect {
  margin-bottom: 2rem;
}

.privateForm input {
  width: 100%;
  height: 45px;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  padding: 10px;
  margin-top: 0.8rem;
}

.privateInfo__container form span {
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
}

.privateFormSelect .css-13cymwt-control {
  min-height: 5rem;
}

.privateFormSelect .css-t3ipsp-control {
  min-height: 5rem;
}

/* .privateInfo__container form button {
      margin-left: auto;
  } */
.privateInfo__container button {
  width: 100%;
  height: 45px;
  margin: 52px 0 0 0;
}

.privateFormSelect .selectItems {
  margin-top: 0.6rem;
}

.user_profile_img {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 40px;
}

.user_form_img input {
  display: none;
}

.user_form_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 999;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.usr_img img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%; */
}

.user_default_img {
  width: 40px !important;
  height: 40px !important;
}

.usr_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 104px;
  height: 104px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  /* overflow: hidden; */
  /* background-image: url("../../../assets/icons/user_default.svg"); */
  background-color: #b7b7b7;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.usr_provide_id span {
  color: #d4252a;
}

.usr_provide_id {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  text-align: left;
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #000;
}

.edit_user_img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../../../assets/icons/edit_user_img.svg");
  background-color: #b7b7b7;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

@media only screen and (max-width: 820px) {
  .privateInfo__container {
    grid-template-columns: 1fr;
    gap: 0rem;
  }

  .privateInfo__container form h3 {
    display: none;
    flex-direction: row;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }

  .privateInfo__container form h3 svg {
    font-size: 2rem;
  }
}

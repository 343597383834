.order__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0rem 0rem;
}

.order__item{
    max-width: 50rem;
    width: 100%;
    padding:4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    border: 1px solid #DDDDDD;
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
    border-radius: 1rem;
}


@media only screen and (max-width: 600px) {
    .order__container{
        padding: 4rem 0rem 0rem;
    }
}
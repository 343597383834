.brand {
    padding: 6rem 0rem 2.5rem;
    display: grid;
    grid-template-columns: 20rem 4fr;
    gap: 1.6rem;
    align-items: flex-start;
    overflow: hidden;
    height: 400px;
}

.brand.opened {
    height: 500px;
}

.brand .brand_tabs {
    height: 255px;
    overflow: auto;
}

.brand.opened .brand_tabs {
    height: 370px;
}

/* all brovser */
.brand .brand_tabs::-webkit-scrollbar {
    width: 5px;
}

.brand .brand_tabs::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e8eaed;
    transition: all 0.3s ease;
    cursor: pointer;
}

.brand .brand_tabs::-webkit-scrollbar-track {
    border-radius: 3px;
}

.brand .brand_tabs:hover::-webkit-scrollbar-thumb {
    background-color: #e8eaed;
}


.brand__container::-webkit-scrollbar,
.allBrand::-webkit-scrollbar {
    width: 12px;
}

.brand__container::-webkit-scrollbar-thumb,
.allBrand::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #e8eaed;
    transition: all 0.3s ease;
}

.brand__container::-webkit-scrollbar-track,
.allBrand::-webkit-scrollbar-track {
    border-radius: 3px;
}

.br_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}

.br_col {
    display: flex;
    /* float: left;
    width: 20%;
    padding: 0 0.6rem 1.2rem 0.6rem; */
}

.br_col_in {
    display: flex;
    width: 100%;
    padding: 1.6rem 2.4rem;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.br_col_in a {
    display: flex;
    align-items: center;
    width: 100%;
}

.brand h3 {
    grid-column: 1 / -1;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.brand__container {
    border-left: 1px solid #D7D7D7;
    padding-left: 1.6rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    gap: 2.6rem;
    height: 100%;
    overflow: visible;
}

.brand__container,
.allBrand {
    padding-right: 1rem;
    overflow: hidden;
    overflow-y: auto;
}

/* .opened .brand__container,
.opened .allBrand {
    padding-right: 1rem;
    overflow: hidden;
    overflow-y: auto;
} */

.br_arw svg {
    display: none;
}

.arw_r_b {
    display: block;
    float: right;
    width: 24px;
    height: 24px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url("../../../assets/icons/arw_right_b.svg");
}

.allBrand {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 8px;
    max-height: 100%;
    /* overflow: visible; */
    /* padding: 2rem; */
    /* background: #F1F1F1; */
    /* border-radius: 5px; */
}

.allBrand a {
    color: var(--color-black);
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.5;
}

.moreDrop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 2rem auto 0 auto;
    cursor: pointer;
}

.moreDrop i {
    margin-left: 6px;
}

.openedDrop i {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.more_text {
    display: block;
}

.less_text {
    display: none;
}

.openedDrop .more_text {
    display: none;
}

.openedDrop .less_text {
    display: block;
}

@media only screen and (max-width: 820px) {
    .brand {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2.5rem 0rem;
        height: auto;
    }

    .brand__container {
        grid-template-columns: repeat(auto-fill, minmax(11.6rem, 1fr));
        gap: 1.6rem;
        overflow: hidden;
    }

    /* .brand__container.opened {
        overflow: visible;
        height: auto;
    } */

    .brand__container,
    .allBrand {
        height: auto;
        max-height: 28rem;
        overflow: auto;
    }

    .moreDrop {
        display: none;
    }

    .brand .brand_tabs {
        display: none;
    }
}
.wishlist__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wishlist__title h3 {
    font-weight: 400;
    font-size: 18px;
    color: var(--color-black);
}

.wishlist__title p {
    font-weight: 400;
    font-size: 14px;
    color: #A0A0A0;
}

.wishlist__title p strong {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-black);
}

.wishlist__cards svg {
    fill: #ff0000;

}

.wishlist__cards {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap: 2rem;
}


.wishlist__tabs p {
    margin-left: auto;
}

.wishlist__tabs {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 40px 0 24px;
}

.wishlist__tabs button.active::before {
    background: #639A46;
}

.wishlist__tabs button::before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent;
    transition: var(--transition);
}

.wishlist__tabs button {
    position: relative;
    padding: 0.8rem;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4;
    color: var(--color-black);
    cursor: pointer;
    background: transparent;
    border-bottom: 1px solid #D7D7D7;
}

@media only screen and (max-width: 820px) {
    .wishlist__cards {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    }

    .wishlist__title h3 {
        display: none;
    }

    .wishlist__tabs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 24px 0;
    }

    .wishlist__tabs button {
        width: 50%;
    }

    .wishlist__tabs p {
        margin: 24px 0 0 0;
    }
}

@media only screen and (max-width: 600px) {
    .wishlist__cards {
        gap: 1rem;
    }
}
.video__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  gap: 2rem;
}

.video__container article {
  height: 22rem;
  position: relative;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.video__container article iframe {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content2 {
  width: 70%;
  height: auto;
  position: relative;
}

.modal-close-video {
  position: absolute;
  top: -10px;
  right: -20px;
  border: none;
  background-color: transparent;
  cursor: pointer !important;
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
}


.modal-content2 iframe {
  width: 100% !important;
  height: 56rem !important;
  border-radius: 3px;
}


.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.50);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.video-overlay h4 {
  max-width: 24.2rem;
  padding: 0 1.6rem;
  text-align: center;
  font-size: 3.7rem;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  letter-spacing: 1.866px;
  color: #FFF;
}

.ytb_icon::before {
  content: "";
  display: block;
  width: 56px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../../assets/icons/youtube_hub.svg");
}

.ytb_icon {
  position: absolute;
  left: 0.8rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  color: #fff;
}

.video-overlay span svg {
  background: transparent;
}

.video-title {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;
  margin-bottom: 3.2rem;
}

.search_vieo {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.search_vieo input {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  padding-right: 40px;
}

.search_btn_youtube {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 4px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url("../../assets/icons/search_btn.svg");
}

@media only screen and (max-width: 1024px) {
  .video-title {
    margin-top: 3rem;
  }

  .video__container {
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  }

  .video__container article {
    height: 19rem;
  }

  .video-overlay h4 {
    font-size: 3rem;
    line-height: 26px;
  }

  .modal-content2 iframe {
    height: 39rem !important;
  }

  .modal-content2 {
    width: 80%;
  }

}


@media only screen and (max-width: 600px) {
  .modal-content2 iframe {
    height: 29rem !important;
  }

  .modal-content2 {
    width: 94%;
  }

  .modal-close-video {
    display: none;
  }
}
.carts__container {
    margin-top: 4rem !important;
    display: grid;
    grid-template-columns: 20rem 4fr;
    gap: 4rem;
}

.totalDelivery {
    margin-top: 6.5rem;
}

.borderTotal {
    border-top: 1px solid var(--color-black);
    padding: 2rem 1rem;
}

.cartPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.cartPrice:nth-child(1) h3 {
    font-weight: 400;
    font-size: 16px;
}

.totalPrice {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.cartTitle {
    margin: 32px 0;
    color: #D4252A;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
}

.modalCart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    height: auto;
    text-align: left;
}

.modalCourier {
    gap: 1rem;
}

/* .modalHeight{
    height: 100% ;
} */

.cart_h5 {
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
}

.modalCartFont h2 {
    font-weight: 600;
    font-size: 18px;
}

.modalCartFont h3 {
    font-weight: 400;
    font-size: 14px;
}

.modalCartFont p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    margin-top: 3rem;
}

.modalCart form {
    padding: 24px 80px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.modalCourier form {
    padding: 24px 40px;
    gap: 1rem;
    width: 100%;
}

.formGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    /*row-gap: 0.8rem;*/
    margin-bottom: 1.5rem;
}

.formGroup h3 {
    grid-column: 1 / span 2;
    font-size: 14px;
}

.formGroup .formItem {
    grid-column: 1 / span 1;
}

.formGroup .formItem2 {
    grid-column: 2 / span 1;
    text-align: left;
}

.formItemOne {
    grid-column: 1 / span 2;
}

.selectItem span {
    display: none;
}

.selectItem input {
    height: 40px !important;
}

.selectItem {
    margin-top: .5rem;
}

.selectContainer {
    height: 5rem;
}

.modalCart form input {
    width: 100%;
    height: 50px;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding: 10px;
    margin-top: 0.6rem;
}

.formItemOne textarea {
    width: 100% !important;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding: 10px;
    margin: 1rem 0 3rem;
    resize: none;
}

.modalCart form span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8E8E8E;
}

.next {
    margin-left: auto;
    display: block;
}

.tabUser {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 100%;
    background: #E8E8E8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formItemFlex {
    display: flex;
    position: relative;
}

.formItemFlex button {
    position: absolute;
    right: 0px;
    top: 6px;
    height: 89%;
    font-size: 1.4rem;
    padding: 1rem 1.2rem !important;
}

/* Updates */
.order_wrong_title p {
    padding: 0;
    margin: 0.4rem auto;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
}

.order_ftr_btns {
    display: flex;
    justify-content: flex-end;
    gap: 1.5rem;
    width: 100%;
    flex-wrap: wrap;
}

/* Updates */
@media only screen and (max-width: 1024px) {
    .carts__container {
        margin-top: 2.5rem !important;
        grid-template-columns: 1fr;
        gap: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .modalCourier form {
        padding: 24px 0px;
    }

    .order_ftr_btns {
        flex-direction: column;
        gap: 0.8rem;
    }

    .order_btn {
        background: #68a834;
        color: #fff;
    }
}
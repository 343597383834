.cardealer_content {
    display: block;
    width: 100%;
    padding: 104px 0;
}

.itm_title {
    display: block;
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    text-align: center;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    opacity: 1;
    z-index: 4;
}

.item_img:hover:after {
    opacity: 0.8;
}

.item_img:hover img {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.item_img {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.item_img img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
}

.item_img::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.item_img::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 0%, rgba(0, 0, 0, 0.34) 48.44%, rgba(0, 0, 0, 0.70) 100%);
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    z-index: 2;
}

.row_itm {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -15px;
}

.col_itm {
    display: block;
    float: left;
    width: 33.33%;
    padding: 0 15px;
}

.col_in {
    display: block;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .cardealer_content {
        padding: 24px 0;
    }

    .itm_title {
        bottom: 1.5rem;
        font-size: 2.2rem;
    }

    .row_itm {
        margin: 0 -8px;
    }

    .col_itm {
        width: 33.33%;
        padding: 0 8px;
    }

}

@media only screen and (min-width: 561px) and (max-width: 767px) {
    .cardealer_content {
        padding: 24px 0;
    }

    .itm_title {
        bottom: 1.5rem;
        font-size: 2.2rem;
    }

    .row_itm {
        margin: 0 -8px;
    }

    .col_itm {
        width: 33.33%;
        padding: 0 8px;
    }

}

@media only screen and (min-width: 0px) and (max-width: 560px) {
    .cardealer_content {
        padding: 24px 0;
    }

    .itm_title {
        bottom: 2rem;
        font-size: 2.5rem;
    }

    .item_img::before {
        padding-top: 74%;
    }

    .row_itm {
        margin: 0;
    }

    .col_itm {
        float: none;
        width: 100%;
        padding: 0 0 15px 0;
    }

}
.products {
    margin-top: 5rem !important;
}

.products h3 {
    font-weight: 500;
}

.products__container {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 2rem;
}

.products__container img {
    height: 16.7rem !important;
}

.products__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4rem;
}

.selectItem {
    width: 17.2rem;
}


@media only screen and (max-width: 600px) {
    .products {
        margin-top: 2.4rem !important;
    }

    .products {
        margin-top: 3rem;
    }

    .products__container {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        gap: 1rem;
    }

    .products__container img {
        height: 9.8rem !important;
    }

}
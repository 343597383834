.notification__container h3 {
    font-weight: 500;
    color: #000;
}

.notif_header {
    display: block;
    position: relative;
    padding: 0 32px 32px 0;
}

.notif_setting {
    display: block;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url("../../../assets/icons/notif_setting.svg");
}

.notif_container {
    display: block;
    width: calc(100% + 40px);
    margin-left: -40px;
}

.notif_items:hover,
.notif_items.active {
    background-color: #EFF6E8;
}

.notification__inner .notif_items:hover {
    background-color: transparent;
}

.notification__inner .notif_items {
    border: none;
    padding: 0;
}

.notification__inner .notif_container {
    width: 100%;
    margin-left: 0;
}

.notif_items {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    padding: 16px 24px;
    gap: 16px;
    cursor: pointer;
    border-bottom: 3px solid #F1F1F1;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
}

.notif_items.active .notif_index::before {
    display: block;
}

.notif_index::before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #639A46;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.notif_index {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 18px;
    gap: 8px;
    float: left;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
}

.notif_content {
    display: block;
    float: left;
    width: auto;
    padding-right: 16px;
}

.notif_title {
    display: block;
    width: auto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    color: #000;
}

.notif_info {
    display: block;
    margin-top: 8px;
    width: auto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
    word-break: break-word;
}

.notification__all .notif_info {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
}

.notif_date_time {
    display: flex;
    width: auto;
    gap: 32px;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #757575;
}

.notif_read {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 24px;
    bottom: 16px;
    width: auto;
    gap: 6px;
    text-align: left;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    color: #757575;
    cursor: pointer;
}

.notif_read::before {
    content: "";
    display: block;
    width: 20px;
    height: 6px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url("../../../assets/icons/notif_more.svg");
}

/* Switch */
.notif_set_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-bottom: 32px;
}

.notif_set_item {
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

.switch_item {
    display: flex;
    align-items: center;
    width: auto;
    cursor: pointer;
}

.switch_item input {
    display: none;
}

.switch_circle {
    display: flex;
    align-items: center;
    width: 40px;
    height: 20px;
    background: #fff;
    border: 1px solid #757575;
    position: relative;
    transition: all ease 0.25s;
    -webkit-transition: all ease 0.25s;
    -moz-transition: all ease 0.25s;
    -ms-transition: all ease 0.25s;
    -o-transition: all ease 0.25s;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.switch_circle::before {
    content: "";
    display: block;
    background: #757575;
    position: absolute;
    left: 2px;
    right: auto;
    width: 16px;
    height: 16px;
    transition: all ease 0.25s;
    -webkit-transition: all ease 0.25s;
    -moz-transition: all ease 0.25s;
    -ms-transition: all ease 0.25s;
    -o-transition: all ease 0.25s;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.switch_item input:checked ~ .switch_circle {
    background: #639A46;
    border-color: #639A46;
}

.switch_item input:checked ~ .switch_circle::before {
    left: auto;
    right: 2px;
    background: #fff;
}

/* Switch */

@media only screen and (max-width: 600px) {
    .notification__container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .notification__inner .notif_items {
        flex-direction: column-reverse;
    }

    .notification__all .notif_container {
        display: block;
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }


    .notification__all .notif_items {
        padding: 48px 20px 16px 20px;
    }

    .notification__all .notif_date_time {
        gap: 16px;
        position: absolute;
        top: 16px;
    }

    .notif_header {
        padding: 0 24px 0px 0;
    }

    .notif_setting {
        top: 4px;
        width: 16px;
        height: 16px;
    }

    .notif_content {
        padding-right: 0;
    }

    .notif_read span {
        display: none;
    }

    .notif_read {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        right: 0;
        bottom: auto;
        width: auto;
        gap: 0px;
        margin: auto 0;
    }

    .notif_read::before {
        width: 24px;
        height: 24px;
        background-image: url("../../../assets/icons/arw_right_b.svg");
    }
}
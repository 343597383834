.map_main {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0;
  margin-top: 4rem !important;
  /* height: 100vh; */
}

.goMapTop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
}

.goMapTop__location {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  /* margin-top: 1.5rem; */
  font-weight: 500;
  font-size: 24px;
}

.goMapTop__location_parargraf {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #181818;
}

.goMapTop__location_icon {
  color: #639A46;
  font-size: 2rem;
}

.goMapTop__buttons {
  display: flex;
  /* margin: 1rem 0 3rem 25%; */
  margin: 0rem auto;
  width: fit-content;
  height: 3.8rem;
  padding: 0.3rem 0.3rem;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  text-align: center;
}

.goMapTop__buttons_btn {
  padding: 0.3rem 3rem;
  width: 12.6rem;
  height: 3rem;
  background-color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  cursor: pointer;
}

.goMapTop__buttons_btn:first-child:active {
  background-color: #bbbbbb;
}

.goMapTop__buttons button:hover {
  background-color: #e5e5e5;
}

.adress {
  display: flex;
  gap: 5.6rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 0 auto;
}

.mercedes {
  display: flex;
  flex-wrap: wrap;
  gap: 5.6rem;
  /* width: calc(100% - 32.5rem); */
  width: auto;
  float: left;
}

.adress__image {
  overflow: hidden;
  width: 26.5rem;
  float: left;
}

.adress__image img {
  background-position: center;
  height: 128px;

}

.adress__MercedesCenter {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  font-weight: 400;
  font-size: 1.2rem;
}

.direction {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 12.8rem;
  border-left: 1px solid #737373;
  padding-left: 5.6rem;
}

.adress__MercedesCenter_info,
.direction__info {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}

.direction__info {
  font-size: 1.4rem;
  width: 24rem;
}

.adress__MercedesCenter_title,
.direction__title {
  font-weight: 500;
  font-size: 2rem;
  width: max-content;
}

.adress__MercedesCenter_info li:first-child {
  font-weight: 500;
  font-size: 1.4rem;
}

.adress__MercedesCenter_info li i {
  font-size: 1.4rem;
  margin-right: 1.7rem;
  color: #bbbbbb;
}

.direction__info li i {
  font-size: 1.4rem;
  margin-right: 1rem;
  color: #bbbbbb;
}

.direction__info li:nth-child(2) {
  display: flex;
  align-items: center;
}

/* .direction__info_trainText {
  margin-left: 1.6rem;
} */

.direction__info_icon i {
  margin: 0 1rem;
  color: #68a834 !important;
}

.map {
  /* max-width: 144rem; */
  width: 100%;
  margin: 1rem auto;
  padding: 0 2rem;
}


.arw_desk {
  display: block;
}

.mapMobile,
.arw_mob {
  display: none;
  color: #000;
}


@media (max-width: 1024px) {
  .adress__image {
    height: auto;

  }

  .adress__image img {
    height: auto;
  }

  .mercedes {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    float: none;

  }

  .goMapTop__location {
    display: flex;
  }

  .mapDesk,
  .arw_desk {
    display: none;
  }

  .arw_mob {
    display: block;
  }

  .mapMobile {
    display: flex;
    margin-top: 2.4rem;
  }


  .adress__MercedesCenter {
    max-width: 100%;
    width: 100%;
  }

  .direction {
    width: 100%;
    padding: 0;
    padding-top: 1.6rem;
    border: none;
    border-top: 1px solid #737373;
  }

  .goMapTop__buttons {
    /* width: 100%; */
    padding: 0.3rem 0.2rem;
  }

  .goMapTop__buttons_btn {
    /* width: 33.3%; */
    padding: 0.3rem 0.3rem;
  }

  .adress__image {
    max-width: 35rem;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .adress__MercedesCenter_title,
  .direction__title {
    font-size: 1.6rem;
  }

  .map .goMapTop {
    margin-bottom: 2.4rem;
  }
}
.shopCard {
    padding: 1rem;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    transition: var(--transition);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
}

.shopCard img {
    margin: 1rem 0rem;
    height: 8rem;
    object-fit: contain;
}

.dealerCard {
    padding: 0rem 1rem 1rem;
}

.auto_shop_card {
    min-height: 20rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-between;
}

.auto_shop_card .shopCard_cropimg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4rem auto 1rem auto;
    padding: 2.4rem;
    /* width: auto;
    height: auto; */
    width: 10.8rem;
    height: 10.8rem;
    background: #111;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.auto_shop_card .shopCard_cropimg img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    object-fit: contain;
    /* border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
}

.auto_shop_card h3 {
    margin-top: auto;
}

.avto_second_title h2 {
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    color: #000;
}

.auto_create_link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: -5rem 0 2.4rem auto;
}

.autosalon_container .register_link {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.8rem;
    color: #68A834;
}

.dealerCard img {
    margin: 0rem 0rem;
}

.shop__card__link {
    display: flex;
    justify-content: center;
    position: relative;
    top: -2rem;
}

.shop__card__link img {
    width: 10.8rem;
    height: 10.8rem;
    border-radius: 100%;
}

.shopCard h3 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--color-black);
    text-align: center;
    min-height: 4rem;
}

/* .dealerCard h3{
    min-height: 5rem;
} */

.dealerCard__desc {
    font-weight: 500;
    font-size: 11px;
    color: #AFAFAF;
    text-align: center;
}

.shopCard__count {
    border-top: 1px solid #D0D0D0;
    padding: 1.4rem 0rem 0.5rem;
    text-align: center;
    margin-top: 1rem;
}

.shopCard__count span {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 14px;

}

.shopCard:hover {
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
}

.auto_create_link.mobile {
    display: none;
}

@media only screen and (max-width:1024px) {
    .shop__card__link img {
        width: 8rem;
        height: 8rem;
    }

    .shopCard h3 {
        font-size: 12px;
    }

    .avto_second_title {
        display: flex;
        align-items: center;
    }

    .auto_create_link {
        display: none;
    }

    .auto_create_link.mobile {
        display: block;
        justify-content: center;
        margin: 0 0 0 auto;
    }

    .autosalon_container .register_link {
        font-size: 1.6rem;
        color: #000;
    }

    .rightArw {
        width: 1.6rem !important;
        height: 1.6rem !important;
        margin-left: 0.4rem !important;
        background-size: contain !important;
        background-image: url("../icons/right_arw_b.svg") !important;
    }
}
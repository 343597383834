
.shopImg{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2rem;
}
.shopImg img{
    width: auto;
    height: 13rem;
    border-radius: 100%;
}
.shopContainer{
    margin-top: 6rem;
    text-align: center;
}
.shopContainer h3{
    display: inline-flex;
    align-items: center;
    gap:1rem;
    cursor: pointer;
}

.shop__category{
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(15.6rem , 1fr));
    gap: 2.6rem;
}

.selectItem{
    display: none;
}

.mobil__shop__info{
    padding: 2rem 0rem 0rem;
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

}

.mobil__shop__info p{
    display: flex;
    align-items: center;
    gap:1rem;
    font-weight: 500;
    font-size: 17px;
    color:var(--color-primary);
    cursor: pointer;
}

.showFilterBtns{
    display: flex;
    justify-content: center;
    padding: 3rem;
    gap:2rem;
}

.showFilterBtns button{
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 16px;
    color: #BEBEBE;
    background: transparent;
    padding:0.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 1024px) {
    .mobil__shop__info{
    display: flex;
    }
}


@media only screen and (max-width: 600px) {
    .shopContainer{
        margin-top: 3rem;
    }
    .selectItem{
        display: block;
        margin-top: 2rem;
    }
}


.service__card {
    /*width: 20rem;
    */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}


.service__card .service__card__img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service__card .service__card__img img {
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    object-position: center;
    height: 20rem;
}

.service__card__body {
    padding: 1.6rem 1rem;
    background: #fff;
    border: none;
    height: 100%;
}

.service__card__body p {
    color: #858585;
    margin-top: 1.3rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.service__card__body p a {
    color: #858585;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.service__card__body p svg {
    color: var(--color-primary);
    font-size: 1.8rem;
    width: 2rem;
}

.service__card__btn {
    position: relative;
    bottom: 0px;
    font-size: 1.2rem !important;
    line-height: 1 !important;
    border-radius: 0px 0px 5px 5px !important;
    -webkit-border-radius: 0px 0px 5px 5px !important;
    -moz-border-radius: 0px 0px 5px 5px !important;
    -ms-border-radius: 0px 0px 5px 5px !important;
    -o-border-radius: 0px 0px 5px 5px !important;
}

@media only screen and (max-width: 600px) {

    .service__card__body {
        border: none;
    }

    .service__card__btn {
        bottom: 0px;
        border-radius: 0px 0px 5px 5px;
        /* font-size: 1.4rem !important; */
        padding: 1rem 1.5rem !important;
    }

    .service__card__body p svg {
        font-size: 1.5rem;
        width: 1.5rem;
    }


}
.tabs_mbrand {
    display: none;
    position: absolute;
    padding: 10px 0;
    top: 40px;
    width: 100%;
    height: 240px;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}


.tabs_mbrand li {
    margin-bottom: 1.6rem;
}

.tabs_mbrand a {
    width: 100%;
    padding: 0px 15px;
    display: block;
    color: var(--color-black);
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

.mob_select_tab {
    display: flex;
    flex-wrap: wrap;
    position: relative;
}


.mob_select_val {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 38px;
    color: var(--color-black);
    font-size: 1.4rem;
    line-height: 1;
    padding: 4px 40px 4px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.mob_select_val::before {
    content: "";
    display: block;
    position: absolute;
    right: 16px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../../../assets/icons/arw_drop.svg");
}



@media only screen and (max-width: 820px) {}

@media only screen and (min-width: 820px) {
    .mob_select_tab {
        display: none !important;
    }
}
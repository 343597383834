.selectItem {
  margin-top: 1.5rem;
  width: 17.2rem;
}

.shop__list p {
  font-weight: 500;
  font-size: 1.6rem;
  color: #656565;
}

.shop__list__items {
  margin-top: 2.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 1.5rem;
}

.head,
.lnone {
  display: none;
}

@media only screen and (max-width: 992px) {
  .lnone {
    display: block;
    color: #181818;
    position: absolute;
  }

  .head {
    display: block;
    text-align: center;
    margin-top: 3rem;
    font-size: 1.6rem;
  }


}


@media only screen and (max-width: 600px) {
  .shop__list__items {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }

  .selectItem {
    width: 100%;
  }
}
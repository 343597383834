.appointment-container-item {
    margin-top: 4rem;
}

.service__right {
    padding: 2.4rem !important;
}

.appointment-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto 2.4rem auto;
}

.appointment-form {
    width: 30%;
}

.form-group {
    margin-bottom: 2.4rem;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

.form-group input:hover,
.form-group textarea:hover {
    cursor: text;
}

.form-group input,
.form-group select,
.form-group textarea {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-group textarea {
    margin-bottom: 0;
}

.submit-button {
    display: block;
    margin-left: auto;
    width: 100%;
    max-width: 280px;
    padding: 10px 20px;
    background: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1.6rem;
    cursor: pointer;
}

.submit-button:hover {
    background: #218838;
}

.calendar-container {
    width: 68%;
    margin-top: 2.6rem;
    display: flex;
    justify-content: space-between;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.calendar {
    width: 65%;
    padding: 20px;
}

.react-calendar {
    background: transparent !important;
    border: none !important;
}

.react-calendar__month-view__days {
    gap: 1px !important;
}

.react-calendar__viewContainer button {
    flex: 0 0 14% !important;
    background: #EFEFEF;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.react-calendar__tile--active {
    background: #639A46 !important;
}

.react-calendar__month-view__days__day.disavailable {
    background: #D4252A !important;
    color: #fff !important;
}

.time-slots-sect {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 20px;
    border-left: 1px solid #B7B7B7;
}

.time-slots {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.time-slot {
    width: calc(50% - 12px);
    height: 32px;
    padding: 5px;
    margin: 6px;
    text-align: center;
    cursor: pointer;
    background: #f8f9fa;
    border: 1px solid #B7B7B7;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #000;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.time-slot:hover {
    border-color: #639A46;
    background: #639A46;
    color: #fff;
}

/* .time-slot.available {
    border-color: #639A46;
    background: #639A46;
    color: #fff;
} */

.time-slot.selected {
    border-color: #639A46;
    background: #639A46;
    color: #fff;
}

.time-slot.disavailable {
    border-color: #D4252A;
    background: #D4252A;
    color: #fff;
}

.date-title {
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

.randevu_title {
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
}

@media only screen and (max-width: 1024px) {
    .appointment-form {
        width: 100%;
    }

    .calendar-container {
        flex-wrap: wrap;
        width: 100%;
        margin-top: 4rem;
        border: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .calendar {
        width: 100%;
        padding: 20px 16px;
        border: 1px solid #B7B7B7;
    }

    .react-calendar {
        width: 100%;
    }

    .time-slots-sect {
        width: 100%;
        margin-top: 1.6rem;
        padding: 20px 10px;
        border: none;
        border: 1px solid #B7B7B7;
    }

    .time-slot {
        width: calc(20% - 4px);
        height: 32px;
        padding: 5px;
        margin: 2px;
    }

    .service__right {
        padding: 0rem !important;
    }

    .submit-button {
        margin: 0 auto;
        max-width: 100%;
    }
}
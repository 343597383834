.video__container__home{
    margin-top:4rem !important;
}
.video__home{
    margin-top:3rem;
    display: grid;
    grid-template-columns: repeat(4 ,1fr);
    grid-template-rows: repeat(2, 22rem);
    gap:2rem;
}

.itemVideo1{
    grid-column: 1 / 2;
}
.itemVideo2{

    grid-column: 2 / 4;
    grid-row: 1  / 3;
}

.video__home iframe{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.video__container__home h3{
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}


@media only screen and (max-width: 912px) {
    .video__home{
        grid-template-columns: repeat(2 ,1fr);
        
    }
    .itemVideo2{
        grid-column: auto;
        grid-row: auto;
    }
    .video__home iframe{
        width: 100%;
        height: 20rem;
    }
}
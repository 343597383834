.forgot_main {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.forgot_content {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
    max-width: 38rem;
    margin: 0 1rem 0 1rem;
}

.forgot_content .contentText {
    color: #B7B7B7;
    font-size: 16px;
    margin-top: 7px;
}

.forgot_content h3 {
    font-size: 20px;
}

.request_btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--color-primary) !important;
    font-size: 14px;
}

.forgot_content input {
    border: 1px solid #B7B7B7;
    height: 4rem;
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 4px;
}

.success_btn {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

.success_btn button {
    display: flex;
    justify-content: center;
    width: 100%;
}

.timer {
    font-size: 18px;
}

/* !Update register form */
.forgot_content {
    max-width: 40rem;
}

.send_btn {
    margin: 0 auto;
    width: 100%;
}

.contentText {
    color: #D7D7D7;
    margin: 2.4rem auto;
    font-size: 1.8rem;
    line-height: 1.3;
}

.timer {
    font-size: 2.1rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 1.6rem;
}

.request_btn {
    font-size: 2.1rem;
    color: #fff !important;
    font-weight: 500;
    margin-bottom: 3.2rem;
}


.recovery_input label {
    font-size: 1.5rem;
    color: #fff;
    margin-top: 4rem;
}

.update_register_form h3 {
    font-size: 2.5rem;
}

@media only screen and (max-width: 767px) {
    .main {
        padding-top: 2.4rem;
        height: auto;
    }

    .content p,
    .contentText {
        color: #7C7C7C;
    }

    .recovery_input label,
    .timer,
    .request_btn {
        color: #181818 !important;
    }
}

/* !Update register form */
.image {
  width: 69px;
  height: 69px;
  border: 1px solid #d0d0d0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent h3 {
  font-size: 24px;
  color: #1e244d;
  font-weight: 700;
  line-height: 150%;
}

.modalContent h4 {
  font-size: 13px;
  font-weight: 300;
  line-height: 1, 6rem;
  margin-top: 1rem;
}

.modalContent {
  background: #fff;
  padding: 65px;
  max-width: 560px;
  width: 100%;
  text-align: center;
  box-shadow: 0 1px 7px rgba(36, 43, 88, 0.06);
  position: relative;
}
.modalButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.modalButtons button {
  margin-top: 30px;
  padding: 16px 50px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
}
.modalButtons span {
  margin-top: 30px;
  padding: 16px 50px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
}
.closeModal {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1001;
}

.modalButtons span:first-child {
  color: #4caf50;
}

.modalButtons button:last-child {
  background: #d4252a;
  color: white;
}

.closeModal {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.modalOverlay {
  background: rgba(0, 0, 0, 0.7); /* Daha qaranlıq bir overlay */
}

.cartItem img {
  display: inline-block;
  object-fit: cover;
}

.cartItem h5 {
  color: #111;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.cartItem {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  place-items: center;
  gap: 2rem;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 2.5rem 1rem;
  padding-left: 5.5rem;
  position: relative;
}

.item__2 {
  grid-column: 2 / 4;
}

.item__3 {
  grid-column: 4 / 6;
}

.btns {
  display: flex;
}

.btns button {
  font-size: 1.8rem;
  padding: 5px 10px;
}

.btns button:nth-last-child(2) {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.btns button:nth-child(1) {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  cursor: pointer;
}

.btns button:nth-last-child(1) {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
}

.promocod {
  display: flex;
  position: relative;
}

.promocod span {
  position: absolute;
  top: -25px;
  font-weight: 500;
  font-size: 12px;
  color: #858585;
}

.promocod input {
  width: 137px;
  height: 41px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding-left: 12px;
}

.remove {
  background: transparent;
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  right: 1rem;
}

.check {
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 1rem;
  filter: hue-rotate(247deg);
}

/* input[type="checkbox"]:checked {
    background: #68a834 !important;
    accent-color: currentcolor;
} */

.mobilCartItem {
  display: none;
}

.carLists {
  max-width: 160px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}

.carShop {
  margin: 3px 0 5px 0;
  font-weight: 500;
  max-width: 160px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .cartItem {
    display: none;
  }

  .mobilCartItem {
    display: grid;
    grid-template-columns: 78% 15%;
    gap: 3%;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 2.5rem 1rem;
    padding-left: 3.5rem;
    position: relative;
    margin-bottom: 0.5rem;
  }

  .mobilCartLeft {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: center;
  }

  .mobilCartName {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .mobilCartRight {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }

  .mobilCheck {
    top: 2.5rem;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 1rem;
  }

  .btns button {
    font-size: 1.5rem;
    padding: 5px 8px;
  }

  .mobilRemove {
    background: transparent;
    font-weight: 600;
  }

  .mobilPromocod {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }

  .mobilPromocod span {
    font-weight: 500;
    font-size: 10px;
    color: #858585;
  }

  .mobilPromocod input {
    background: #f5f5f5;
    border-radius: 5px;
    width: 12.4rem;
    padding: 1rem;
  }

  .mobilCartRight h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #181818;
    min-width: 7rem;
  }
}

@media only screen and (max-width: 600px) {
  .mobilCartLeft {
    flex-direction: column;
    align-items: flex-start;
  }
}

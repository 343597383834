.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.content {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 46rem;
    width: 100%;
}

.content h3 {
    font-size: 2rem;
    font-size: #181818;
}

.content p {
    color: #7C7C7C;
    margin-top: 2.2rem;
    font-size: 15px;
}

.recovery_input {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 38rem;
    width: 100%;
    margin: 0 auto;
}

.recovery_input input {
    display: flex;
    align-self: center;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding: 5px 10px;
    width: 100%;
    height: 5rem;
    margin-top: 1rem;
}

.recovery_input label {
    font-size: 14px;
    color: #8E8E8E;
    margin-top: 3.8rem;
}

.send_btn {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.recovery_input {
    max-width: 40rem;
}

/* !Update register form */
.send_btn {
    margin: 0 auto;
    width: 100%;
}

.content p {
    color: #D7D7D7;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.3;
}

.recovery_input label {
    font-size: 1.5rem;
    color: #fff;
    margin-top: 4rem;
}

.content h3 {
    font-size: 2.5rem;
}

@media only screen and (max-width: 767px) {
    .main {
        padding-top: 2.4rem;
        height: auto;
    }

    .content p {
        color: #7C7C7C;
    }

    .recovery_input label {
        color: #181818;
    }
}

/* !Update register form */
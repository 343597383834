.menu__container {
    width: 100%;
    background: var(--bg-gray);
    /* height: 4.5rem; */
    height: 4rem;
}

.dropmenu {
    display: none;
    width: 100%;
}

.dropmenu.droped_menu {
    display: block;
}

.dropclick {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 2.4rem;
}

.dropclick::before {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../../../assets/icons/drop_arw.svg");
}

.dropclick.droped_menu::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.lang_name {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 1rem;
    min-width: 5rem;
    height: 30px;
    background-color: #EFEFEF;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu li a {
    /* font-weight: 500; */
    font-weight: 400;
    font-size: 1.5rem;
    color: var(--color-white);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.menu li a img {
    width: 1.8rem;
}


.menu_icns {
    width: 2.4rem;
    height: 2.4rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.shop {
    background-image: url("../../../assets/icons/shops.svg");
}

.dealers {
    background-image: url("../../../assets/icons/dealers.svg");
}

.cardealers {
    background-image: url("../../../assets/icons/cardealers.svg");
}

.licenseplate {
    background-image: url("../../../assets/icons/licenseplate.svg");
}

.repairers {
    background-image: url("../../../assets/icons/repairers.svg");
}

.specialorder {
    background-image: url("../../../assets/icons/specialorder.svg");
}

.services {
    background-image: url("../../../assets/icons/services.svg");
}


/* Mobile icons */
.profile {
    background-image: url("../../../assets/icons/profile.svg");
}

.favorites {
    background-image: url("../../../assets/icons/favorites.svg");
}

.balance {
    background-image: url("../../../assets/icons/balance.svg");
}

.mcategory {
    background-image: url("../../../assets/icons/category_mob.svg");
}

.mshop {
    background-image: url("../../../assets/icons/shops_mob.svg");
}

.mdealers {
    background-image: url("../../../assets/icons/dealers_mob.svg");
}

.mcardealers {
    background-image: url("../../../assets/icons/cardealers_mob.svg");
}

.mlicenseplate {
    background-image: url("../../../assets/icons/licenseplate.svg");
}

.mrepairers {
    background-image: url("../../../assets/icons/repairers_mob.svg");
}

.mspecialorder {
    background-image: url("../../../assets/icons/specialorder.svg");
}

.mservices {
    background-image: url("../../../assets/icons/services_mob.svg");
}

.myoutube {
    background-image: url("../../../assets/icons/ytb_mob.svg");
}

.mlanguage {
    background-image: url("../../../assets/icons/mlang.svg");
}

.mtruck {
    background-image: url("../../../assets/icons/truck_mob.svg");
}

.mgomap {
    background-image: url("../../../assets/icons/gomap_mob.svg");
}

.mexit {
    background-image: url("../../../assets/icons/exit_mob.svg");
}

.mcontact {
    background-image: url("../../../assets/icons/contact_mob.svg");
}




#closeMenu {
    display: none;
}



@media only screen and (max-width: 1024px) {
    .menu__container {
        height: 0px;
    }

    #closeMenu {
        top: 1.6rem;
        position: absolute;
        right: 1.6rem;
        font-size: 2rem;
        background: transparent;
        cursor: pointer;
        display: block;
    }

    .dropclick {
        display: flex !important;
    }

    .menu {
        flex-direction: column;
        max-width: 450px;
        width: 100%;
        left: 0px;
        top: 0px;
        position: fixed;
        z-index: 999999999;
        height: 100vh;
        background: #fff;
        box-shadow: 0px 3px 5px 0px #0000000d;
        padding: 2.4rem 1.6rem 2rem 1.6rem;
        transform: translateX(-100%);
        transition: 0.3s ease-in;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.6rem;
        overflow: auto;
    }

    .menu li {
        width: 100%;
    }

    .menu li a {
        padding-left: 0.8rem;
        font-weight: 500;
        font-size: 14px;
        color: #434242;
        gap: 1.6rem;
    }

    .mobil_flex a {
        display: flex;
        justify-content: space-between;
    }

    .mobil_flex span {
        width: 49px;
        height: 29px;
        background: #EFEFEF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu li p {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        font-weight: 500;
        font-size: 14px;
        color: #1D252B;
        gap: 1.6rem;
    }

    /* Active Class */
    .nav__active {
        transform: translateX(0%);
    }

    .shop {
        background-image: url("../../../assets/icons/shops_mob.svg");
    }

    .dealers {
        background-image: url("../../../assets/icons/dealers_mob.svg");
    }

    .cardealers {
        background-image: url("../../../assets/icons/cardealers_mob.svg");
    }

    .licenseplate {
        background-image: url("../../../assets/icons/licenseplate_mob.svg");
    }

    .repairers {
        background-image: url("../../../assets/icons/repairers_mob.svg");
    }

    .specialorder {
        background-image: url("../../../assets/icons/specialorder_mob.svg");
    }

    .services {
        background-image: url("../../../assets/icons/services_mob.svg");
    }

    .menu li a {
        font-weight: 500;
        gap: 1rem;
        padding-left: 0;
    }

    .menu_icns {
        width: 3.2rem;
        height: 3.2rem;
    }
}
.category{
    padding:2.5rem 0rem;
    display: grid;
    grid-template-columns: 20rem 4fr ;
    gap:4rem;
}

.category h3{
    grid-column: 1 / -1;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.category__container{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(14rem , 1fr));
    gap: 2.5rem;
    align-items: flex-start;
}
.category_mobil_container{
    display: none;
}

@media only screen and (max-width: 820px) {
    .category{
        grid-template-columns: 1fr ;
        gap:2rem;
    }
    .category__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.6rem;
    }
    .category_container_demo{
        display: none;
    }
    .category_mobil_container{
        display: grid;
    }
 
  }
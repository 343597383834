.tabs {
    width: 100%;
    height: 100%;
    border-right: 5px solid #F1F1F1;
}

.tabs li {
    margin-bottom: 2.3rem;
}

.tabs a {
    width: 100%;
    padding: 0px 20px;
    display: block;
    color: var(--color-black);
}

.tabs__mobil__user {
    display: none;
}

.selectItem {
    display: none;
}

.css-1jqq78o-placeholder {
    font-size: 13px !important;
}

.css-1dimb5e-singleValue {
    font-size: 13px !important;
}

.successText {
    color: var(--color-primary);
}

.dangerText {
    color: #FB3939;
}

.returnText {
    color: #FFB930;
}


.shop__search {
    padding: 3rem 0rem;
    display: flex;
    justify-content: center;
}

.shop__search form {
    /* width: 42.2rem; */
    /* height: 33px; */
    width: 30.2rem;
    height: 40px;
    border: 1px solid #C2C2C2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding-left: 2rem;
    background: #fff;
    position: relative;
}

.shop__search form input {
    width: 90%;
    height: 100%;
}

.shop__search form button {
    width: 4rem;
    font-size: 1.5rem;
    background: transparent;
    height: 100%;
    cursor: pointer;
}

.soon__container {
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 55rem;
    text-align: center;
}

.soon h3 {
    margin-top: 3rem;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    color: #181818;
}

.soon p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #737373;

}

.soon img {
    width: 75%;
}

.filterFlex {
    display: none;
}

.filterFlex a,
.filterFlex button {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 16px;
    color: #BEBEBE;
    background: transparent;
    padding-bottom: 3px;
}

.tab__mobil__icon {
    display: none;
}

.tabLi {
    display: flex;
    align-items: center;
}

.mobilLogout {
    display: none;
}

.container__tabs {
    display: block;
}


.mobil__title__cart {
    display: none;
}



.hoverColor:hover {
    transition: var(--transition);
    color: #C2C2C2;
}

.demo__menu__hidden {
    display: none;
}

.brandsList .box_item {
    filter: grayscale(1);

}


.brandsList .box_item img {
    height: 6rem;
    object-fit: contain;
}

.brandsList .box_item:hover {
    filter: grayscale(0);
}

.detailSlider {
    width: 32rem;
}

.autodetail_sect.detailSlider {
    display: block;
    width: 100%;
    margin: 0 auto;
}


.mainSlider_block {
    position: relative;
}

.slick-prev,
.slick-next {
    display: block;
    width: 32px !important;
    height: 32px !important;
    opacity: 1;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.60);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
    opacity: 1;
}

.smallImages .slick-prev,
.smallImages .slick-next {
    display: none !important;
}

.slick-prev {
    left: 24px !important;
}

.slick-next {
    right: 24px !important;
}

.slick-prev:before {
    background-image: url("../icons/prev.svg");
}

.slick-next:before {
    background-image: url("../icons/next.svg");
}

.slide-info {
    display: inline-flex;
    width: auto;
    padding: 8px 12px;
    position: absolute;
    right: 10px;
    bottom: 20px;
    z-index: 5;
    font-size: 15.75px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    background-color: #00000060;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.detailSliderMobil {
    display: none;
}

.errorMessage {
    padding: 0.5rem 0rem;
    color: red;
    font-size: 1.2rem;

}

.telphoneSvg {
    font-size: 1.3rem !important;
}

.showMobilFilter {
    display: none !important;
}


.sm_basket {
    visibility: hidden;
    opacity: 0;
}

.basket_btn:hover .sm_basket {
    visibility: visible !important;
    opacity: 1 !important;
}

.basket_btn {
    padding: 2rem 0;
}

.sect_relative {
    position: relative;
}


.yandexSearch {
    position: absolute;
    top: 80px;
    right: 0px;
    width: 100%;
    background: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
    height: auto;
    overflow: auto;
    border-radius: 0px 0px 4px 4px;
    z-index: 99999999 !important;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding: 1rem 2.5rem;

}

.yandexSearch li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}

.clicked_tab ul.tabs_mbrand {
    display: block;
}

.clicked_tab div::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}



@media only screen and (max-width: 1024px) {
    .mobil__menu__hidden {
        display: none !important;
    }

    .showMobilFilter {
        display: flex !important;
    }

    .showDesktopFilter {
        display: none !important;
    }

    .mobil_line_top {
        border-bottom: 1px solid #B7B7B7;
        padding-bottom: 1.6rem;
    }

    .demo__menu__hidden {
        display: block;
    }

    .tabs li {
        margin-bottom: 1.5rem;
    }

    .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: none;
    }

    .filterFlex {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin-bottom: 2rem;

    }

    .soon img {
        width: 65%;
    }

    .soon__container {
        padding: 4rem 0rem 0rem;
    }

    .soon {
        width: 45rem;
    }

    .soon h3 {
        font-size: 28px;
        line-height: 30px;
    }

    .soon p {
        font-size: 15px;
    }

    .tab__mobil__icon {
        width: 3.4rem;
        height: 3.4rem;
        border-radius: 5px;
        background: #E8E8E8;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerban .mobilNone {
        display: none !important;
    }

    .autodetail_sect.detailSlider .smallImages {
        display: none !important;
    }
}

@media only screen and (max-width: 820px) {
    .mobil__title__cart {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .mobil__title__cart .left__mobil__icon {
        cursor: pointer;
    }

    .active__right__cart {
        display: block !important;
    }

    .activeTabs {
        display: none !important;
    }

    .carts__container__right {
        display: none;
    }

    .tabs__mobil__user {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 3rem;
        position: relative;
    }

    .tabs {
        grid-template-columns: 1fr;
    }

    .tabs li:nth-last-child(1) {
        display: none;
    }

    .selectItem {
        display: block;
        margin-top: 1.5rem;
    }

    .mobilLogout {
        display: block;
    }

    .mobil__none {
        display: none;
    }

    .desktop__title__cart {
        display: none;
    }

    .container__tabs {
        display: grid;
        gap: 1.5rem;
    }

}

@media only screen and (max-width: 600px) {
    .brandsList .box_item img {
        height: 7rem;
    }

    .soon {
        width: 90%;
        gap: 1rem;
    }

    .soon h3 {
        margin-top: 1rem;
        font-size: 22px;
        line-height: 30px;
    }

    .soon p {
        font-size: 13px;
        line-height: 20px;
    }

    .detailSlider {
        display: none;
    }

    .detailSliderMobil {
        display: block;
        width: 35rem !important;
        /* border: 1px solid #D0D0D0; */
        border-radius: 3px;
        /* padding: 1.5rem 1.5rem 2rem; */
        padding-bottom: 2rem;
        margin: 0px auto;
    }

    .detailSliderMobil .slick-slide img {
        object-fit: contain !important;
        /* height: 24rem; */
    }


}

@media only screen and (max-width: 375px) {
    .detailSliderMobil {
        width: 30rem !important;
    }

}

@media only screen and (max-width: 320px) {
    .detailSliderMobil {
        width: 28rem !important;
    }

}
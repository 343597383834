.description_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 0;
}

.desc_info {
    display: block;
    width: 100%;
    padding: 1.6rem;
    border: 1px solid #D7D7D7;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.desc_info p {
    text-align: justify;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: #434242;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
}

.expanded p {
    -webkit-line-clamp: inherit;
}

.shop_modal_sect button {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.shop_modal_sect span {
    color: #111;
}

.shop_modal_sect p {
    color: #000;
}

.show_about {
    display: block;
    float: right;
    text-align: center;
    font-size: 1.9rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;
    color: #fff;
    overflow: hidden;
}

.more_text {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 2.4rem 0 0rem 0;
}

.more_text span {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-right: 22px;
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    cursor: pointer;
}

.more_text.clicked span::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.more_text span::before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    width: 16px;
    height: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../../assets/icons/arw_drop.svg");
}

.shop_name_hd {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.2rem 0 3.2rem 0;
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
}

.shopImgCover {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    overflow: hidden;
    min-height: 38rem;
}

.shopImgCover::before {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    padding-top: 27%;
}

.shopImgCover img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
}

.shopImg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.shopImg img {
    width: auto;
    height: 13rem;
}

.shopContainer {
    margin-top: 6rem;
    text-align: center;
}

.shopContainer h3 {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.shop__category {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15.6rem, 1fr));
    gap: 2.6rem;
}

.selectItem {
    display: none;
}

.mobil__shop__info {
    padding: 2rem 0rem;
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;

}

.mobil__shop__info p {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 17px;
    color: var(--color-primary);
    cursor: pointer;
}

.showFilterBtns {
    display: flex;
    justify-content: center;
    padding: 3rem;
    gap: 2rem;
}

.showFilterBtns button {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 16px;
    color: #BEBEBE;
    background: transparent;
    padding: 0.5rem;
    cursor: pointer;
}

.shopImgMob {
    float: left;
    width: 80px;
}

.shopImgMob img {
    width: 100%;
}

.shop_right_mob {
    float: left;
    padding-left: 1.6rem;
    width: calc(100% - 80px);
}

.shop_detail_mob {
    align-items: flex-start;
    padding: 2rem 0rem;
    border-bottom: 1px solid #D7D7D7;
}

.shop_right_mob .shop_name_hd {
    padding: 0rem 0 1rem 0;
    justify-content: flex-start;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    border-bottom: none;
}

.shop_right_mob p,
.shop_right_mob a {
    color: #111;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.auto_top_sect .shop_detail_mob {
    border: none;
}

.auto_top_sect .shop_right_mob .shop_name_hd {
    color: #fff;
}

.auto_top_sect .shop_right_mob p,
.auto_top_sect .shop_right_mob a {
    color: #fff;
}

.shop_right_mob p svg {
    margin-right: 0.6rem;
}

.shop_right_mob p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.auto_top_sect {
    display: block;
    width: 100%;
    background-color: #000;
    padding: 2rem 1.6rem 4rem 1.6rem;
    margin-bottom: 6rem;
}

.auto_top_sect .more_text span {
    color: #fff;
}

.auto_top_sect .desc_info {
    border: 1px solid #fff;
}

.auto_top_sect .desc_info p {
    color: #fff;
}

.auto_top_sect .more_text span::before {
    background-image: url("../../../assets/icons/arw_drop_w.svg");
}

.filter_items_desk {
    display: block;
}

.filter_items_mobile {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .mobil__shop__info {
        display: flex;
    }

    .auto_top_sect {
        padding: 0;
        margin-bottom: 0.8rem;
    }

    .shopImgCover {
        min-height: 24rem;
    }

    .filter_items_mobile {
        display: block;
    }

    .filter_items_desk {
        display: none;
    }
}


@media only screen and (max-width: 600px) {
    .shopContainer {
        margin-top: 3rem;
    }

    .selectItem {
        display: block;
        margin-top: 2rem;
    }

    .shopImg img {
        width: 80%;
        height: auto;
    }

    .auto_top_sect {
        padding: 0;
        margin-bottom: 0.8rem;
    }


}
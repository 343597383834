.banner {
    width: 100%;
    display: flex;
}

.nobanner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: #181818;
    width: 100%;
    min-height: 64px;
}


.banner a {
    width: 100%;
    height: 100%;
    display: flex;
}
.register__container {
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.register__container form {
    width: 38rem;
}

.register__container h3 {
    text-align: center;
    margin-bottom: 3rem;
}

.register__container form span {
    font-weight: 300;
    font-size: 14px;
    color: #8E8E8E;
}

.register__container form button {
    margin: 0px auto;
}

.register__container a {
    font-weight: 400;
    font-size: 14px;
    color: #181818;
    border-bottom: 1px solid #181818;
}

/* !Update register form */

.open__form__modal.form_login_modal {
    padding: 0px;
    grid-template-columns: 1fr;
    gap: 0.8rem;
}

.update_register_form form {
    width: 100%;
}

.update_register_form {
    max-width: 40rem;
    width: 100%;
    margin: 0 auto;
}

.update_login_form {
    max-width: 28rem;
    width: 100%;
    margin: 0 auto;
}

/* .update_register_form {
    max-width: 28rem;
    width: 100%;
    margin: 0 auto;
} */

.update_register_form .privateForm {
    margin-bottom: 1.2rem !important;
}

.update_register_form .privateForm input {
    height: 40px;
    margin-top: 0.8rem;
}

.update_register_form h3 {
    margin-bottom: 2.2rem;
    font-size: 2.1rem;
    font-weight: 500;
}

.update_register_form form button {
    margin: 2rem auto 2.4rem auto;
    width: 100%;
}

.update_register_form form span {
    font-weight: 400;
    font-size: 15px;
    color: #000;
}

.google_login {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.8rem;
}

.sign_items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
}

.sign_itm_button {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.sign_itm_button span{
    color: #fff;
}

.sign_in_btn {
    display: inline-flex;
    width: auto;
    border: none !important;
    font-size: 19px !important;
    font-weight: 500 !important;
    margin-bottom: 1.6rem;
    color: #000 !important;
    cursor: pointer;
}

.forgot_btn {
    display: inline-flex;
    width: auto;
    border: none !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #757575 !important;
}

.sign_in_btn:hover,
.forgot_btn:hover {
    color: #68a834 !important;
}

.register_succes_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.succes_msg_val {
    text-align: center;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000;
}

.regst_id_val {
    display: block;
    margin-top: 1.2rem;
    text-align: center;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #757575;
}

.back_home_link {
    display: inline-block;
    margin-top: 3.2rem;
    text-align: center;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000;
}

.register_tick {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    margin: 1rem auto 4rem auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("../../assets/icons/tick_register.svg");
}

.sign_up_page {
    display: block;
    padding: 4.4rem 0;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.register_bg::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
}

.register_bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/icons/register_bg.svg");
    z-index: -1;
}

.sign_up_page h3,
.sign_up_page .sign_in_btn,
.sign_up_page .forgot_btn,
.sign_up_page form span {
    color: #fff !important;
}

.sign_up_page .update_login_form h3,
.sign_up_page .update_login_form .sign_in_btn,
.sign_up_page .update_login_form .forgot_btn,
.sign_up_page .update_login_form form span {
    color: #181818 !important;
}


@media only screen and (max-width: 767px) {
    .sign_up_page {
        padding-top: 0;
    }

    .register__container {
        padding: 2.4rem 0rem;
    }

    .login_moda_mobile .modal-content.showModal {
        margin-top: auto !important;
        width: 100% !important;
        height: auto !important;
        max-width: 100% !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        -ms-border-radius: 0 !important;
        -o-border-radius: 0 !important;
    }

    .register_bg {
        position: relative;
        width: 100%;
        height: auto;
        background-image: url("../../assets/icons/register_bg_mob.svg");
        z-index: -1;
    }

    .register_bg::after {
        content: "";
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 56%;
    }

    .sign_up_page h3,
    .sign_up_page .sign_in_btn,
    .sign_up_page .forgot_btn,
    .sign_up_page form span {
        color: #181818 !important;
    }
    .sign_itm_button span{
        color: #181818 !important;
    }
}

/* !Update register form */

.modalCart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    height: 100%;
    text-align: center;
}

.modalCartFont h2 {
    font-weight: 600;
    font-size: 18px;
}

.modalCartFont h3 {
    font-weight: 400;
    font-size: 20px;
}

.modalCartFont p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.eyeIcon {
    position: absolute;
    color: #8E8E8E;
    cursor: pointer;
    top: 50%;
    right: 1.5rem;
}


@media only screen and (max-width: 600px) {
    .register__container form {
        width: 100%;
    }
}